import React, { useState } from 'react';
import { FaArrowLeft, FaGlobe, FaArrowUp } from 'react-icons/fa';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

const languages = {
    en: [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'Backspace'],
        ['123', 'Space', 'ChangeLang', 'Enter'],
    ],
    ru: [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з'],
        ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж'],
        ['Shift', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'Backspace'],
        ['123', 'Space', 'ChangeLang', 'Enter'],
    ]
};

const symbols = [
    ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')'],
    ['-', '_', '+', '=', '{', '}', '[', ']', '|', '\\'],
    ['~', '`', ':', ';', '"', "'", '<', '>', ',', '.', '?'],
    ['ABC', 'Space', 'Enter'],
];

const numeric = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['0', 'Backspace', 'Enter'],
];

const CustomKeyboard = ({ onKeyPress, numericMode = false, isVisible = true, setIsVisible }) => {
    const [language, setLanguage] = useState('en');
    const [isShift, setIsShift] = useState(false);
    const [isSymbols, setIsSymbols] = useState(false);
    const [isSpacePressed, setIsSpacePressed] = useState(false);

    const handleKeyPress = (key) => {
        if (key === 'Enter') {
            if (setIsVisible) {
                setIsVisible(false); // Скрыть клавиатуру при нажатии на "Enter"
            }
            return;
        }

        if (key === 'Shift') {
            setIsShift(!isShift);
        } else if (key === 'ChangeLang') {
            setLanguage(language === 'en' ? 'ru' : 'en');
        } else if (key === '123') {
            setIsSymbols(true);
        } else if (key === 'ABC') {
            setIsSymbols(false);
        } else {
            onKeyPress(key);
        }
    };

    const handleSpaceHold = () => {
        setIsSpacePressed(true);
    };

    const handleSpaceRelease = () => {
        if (isSpacePressed) {
            setLanguage(language === 'en' ? 'ru' : 'en');
        }
        setIsSpacePressed(false);
    };

    if (!isVisible) {
        return null; // Если клавиатура не видна, не рендерить компонент
    }

    return (
        <div
            className="w-full fixed bottom-0 left-0 right-0 bg-gray-800 p-4 md:p-6 flex flex-col space-y-3 z-50"
            style={{ paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box', overflowX: 'hidden', userSelect: 'none', WebkitUserSelect: 'none' }}
            onContextMenu={(e) => e.preventDefault()} // Отключение контекстного меню
        >
            {(numericMode ? numeric : (isSymbols ? symbols : languages[language])).map((row, rowIndex) => (
                <div key={rowIndex} className="flex justify-center space-x-1 md:space-x-2">
                    {row.map((key) => (
                        <button
                            key={key}
                            onClick={() => handleKeyPress(isShift && key.length === 1 ? key.toUpperCase() : key)}
                            className={`${
                                ['Shift', 'Backspace', 'Enter', 'Space', 'ChangeLang', '123', 'ABC'].includes(key)
                                    ? 'bg-gray-600'
                                    : 'bg-gray-700'
                            } text-white text-lg md:text-xl px-3 py-3 md:px-5 md:py-4 rounded focus:outline-none transition-all`}
                            style={{ minWidth: key === 'Space' ? '120px' : 'auto', userSelect: 'none', WebkitUserSelect: 'none' }}
                            onMouseDown={key === 'Space' ? handleSpaceHold : null}
                            onMouseUp={key === 'Space' ? handleSpaceRelease : null}
                            onTouchStart={key === 'Space' ? handleSpaceHold : null}
                            onTouchEnd={key === 'Space' ? handleSpaceRelease : null}
                        >
                            {key === 'Shift' ? (
                                isShift ? <IoIosArrowDown /> : <IoIosArrowUp />
                            ) : key === 'Backspace' ? (
                                <FaArrowLeft />
                            ) : key === 'ChangeLang' ? (
                                <FaGlobe />
                            ) : key === 'Space' ? (
                                `${language.toUpperCase()}`
                            ) : (
                                key
                            )}
                        </button>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default CustomKeyboard;
