export const ADD_TO_CATEGORY = 'ADD_TO_CATEGORY';

const initialState = {
    categories: {}
};

const categoryReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_TO_CATEGORY:
            return {
                ...state,
                categories: action.payload
            };
        default:
            return state;
    }
};

export default categoryReducer;
