export const setSelectedProducts = (products) => {
    return {
        type: 'SET_SELECTED_PRODUCT',
        payload: products
    }
}

// Удалить выбранный продукт
export const removeSelectedProduct = (productId) => {
    return {
        type: 'REMOVE_SELECTED_PRODUCT',
        payload: productId
    }
}

// Очистить выбранные продукты
export const clearSelectedProducts = () => {
    return {
        type: 'CLEAR_SELECTED_PRODUCTS'
    }
}
