import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import SelectedProduct from "../../../SelectedProduct/SelectedProduct";
import { setDrawerOpen } from "../../../store/actions/drawerAction";
import { setSelectedProducts } from "../../../store/actions/selectedProductActions";
import { setItemType } from "../../../store/actions/selectedItemTypeAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDrawerOpen } from "../../../store/selectors/drawerSelector";
import { selectProducts } from "../../../store/selectors/productSelectors";
import { filterProducts } from "../../../../utils";
import { message, Input, Radio, TimePicker, Button, Collapse, Modal, Form, Popconfirm } from "antd";
import { HiCash, HiCreditCard, HiHome, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { addToTotalPrice, subtractFromTotalPrice } from "../../../store/actions/priceAction";
import { makeOrder } from "../../../../Api/order/orderApi";
import dayjs from 'dayjs';

const { Panel } = Collapse;

export default function PaymentContent() {
    const dispatch = useDispatch();
    const [backet, setBacket] = useState([]);
    const pom = useParams();
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const [currentAddress, setCurrentAddress] = useState("");
    const userAddress = localStorage.getItem('address') || 'Адрес не установлен';
    const [additionalAddresses, setAdditionalAddresses] = useState(
        JSON.parse(localStorage.getItem("additionalAddresses")) || []
    );
    const [currentPrice, setCurrentPrice] = useState(0);
    const totalPrice = useSelector(state => state.price.totalPrice);
    const [paymentType, setPaymentType] = useState("Naqd pul");
    const [knockChoice, setKnockChoice] = useState("Ha");
    const [selectedTime, setSelectedTime] = useState(dayjs());
    const [activeKey, setActiveKey] = useState(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [customDescription, setCustomDescription] = useState("");
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editAddressIndex, setEditAddressIndex] = useState(null);
    const [form] = Form.useForm();

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    const handlePaymentChange = (e) => {
        setPaymentType(e.target.value);
    };

    const handleKnockChange = (e) => {
        setKnockChoice(e.target.value);
    };

    useEffect(() => {}, [totalPrice]);

    const products = useSelector(selectProducts);

    const handleAddToTotalPrice = (price) => {
        dispatch(addToTotalPrice(parseInt(price)));
    };

    useEffect(() => {}, []);

    useEffect(() => {
        const basketProducts = filterProducts(products?.products, JSON.parse(localStorage.getItem('basket')));
        setBacket(basketProducts);
        let t = 0;
        basketProducts.forEach(
            (item) => (t += parseInt(item.price) * item.count)
        );
        if (totalPrice === 0) {
            setCurrentPrice(t);
            handleAddToTotalPrice(t);
        } else {
            setCurrentPrice(totalPrice);
        }
        message.info('Mahsulotlar yuklandi');
    }, [products, totalPrice]);

    const confirmOrder = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            let basket = JSON.parse(localStorage.getItem('basket'));

            basket.products = basket.products.filter(product => product.product_id !== 0 && product.count > 0);

            const description = `
                To'lov turi: ${paymentType}, 
                Eshikni taqillatish: ${knockChoice}, 
                Yetkazib berish vaqti: ${selectedTime ? selectedTime.format('HH:mm') : 'Ko\'rsatilmagan'},
                Qo'shimcha ma'lumot: ${customDescription || 'Qo\'shimcha ma\'lumot yo\'q'}
            `;

            const orderData = {
                user_id: userId,
                address: currentAddress || userAddress,
                products: basket.products,
                description: description
            };

            const response = await makeOrder(orderData);
            console.log(response);

            localStorage.removeItem('basket');
            basket = { products: [{ product_id: 0, count: 0 }] };
            localStorage.setItem('basket', JSON.stringify(basket));
            dispatch(subtractFromTotalPrice(currentPrice));
            message.success("Buyurtma muvaffaqiyatli yaratildi!");
        } catch (error) {
            console.error('Buyurtma yaratishda xatolik yuz berdi:', error);
            message.error('Buyurtma yaratishda xatolik yuz berdi');
        }
    };

    const handleAddNewAddress = () => {
        if (currentAddress.trim()) {
            const updatedAddresses = [...additionalAddresses, currentAddress];
            setAdditionalAddresses(updatedAddresses);
            localStorage.setItem(
                "additionalAddresses",
                JSON.stringify(updatedAddresses)
            );
            setCurrentAddress(''); // Сбрасываем поле ввода
            message.success("Yangi manzil qo'shildi");
        } else {
            message.warning("Iltimos, manzilni kiriting!");
        }
    };

    const showEditModal = (address, index) => {
        setEditAddressIndex(index);
        form.setFieldsValue({ address });
        setIsEditModalVisible(true);
    };

    const handleEditAddress = () => {
        form.validateFields()
            .then((values) => {
                const updatedAddresses = [...additionalAddresses];
                updatedAddresses[editAddressIndex] = values.address;
                setAdditionalAddresses(updatedAddresses);
                localStorage.setItem(
                    "additionalAddresses",
                    JSON.stringify(updatedAddresses)
                );
                setIsEditModalVisible(false);
                message.success("Manzil muvaffaqiyatli yangilandi");
            })
            .catch((info) => {
                console.log("Validatsiya xatosi:", info);
            });
    };

    const handleDeleteAddress = (index) => {
        const updatedAddresses = additionalAddresses.filter(
            (_, i) => i !== index
        );
        setAdditionalAddresses(updatedAddresses);
        localStorage.setItem(
            "additionalAddresses",
            JSON.stringify(updatedAddresses)
        );
        if (selectedAddressIndex === index) {
            setCurrentAddress(userAddress);
            setSelectedAddressIndex(null);
        }
        message.success("Manzil o'chirildi");
    };

    const handleSelectAddress = (address, index) => {
        setCurrentAddress(address);
        setSelectedAddressIndex(index);
    };

    const handleResetToDefaultAddress = () => {
        setCurrentAddress(userAddress);
        setSelectedAddressIndex(null);
    };

    const handleCollapseChange = (key) => {
        setActiveKey(key);
    };

    return (
        <div className={'w-full h-full flex flex-col items-center'}>
            <div className={'text-2xl font-bold mb-4'}>
                {currentAddress || userAddress}
            </div>

            <div className={'w-full h-[90%] flex justify-between gap-3'}>
                <div className={`${style.container} p-4 rounded-lg shadow-md bg-white`}>
                    <div className={'text-2xl font-bold my-3 '}>Savatcha</div>

                    {backet.map((value, index) => (
                        <SelectedProduct
                            onClick={() => {
                                dispatch(setDrawerOpen(true));
                                dispatch(setSelectedProducts(value));
                                dispatch(setItemType("saleProduct"));
                            }}
                            key={index}
                            productQuantity={value.count}
                            cover={value.cover}
                            percentPromo={22}
                            capacity={value.price}
                            title={value.name}
                            oldPrice={value.price}
                            price={value.price}
                            badgeText={'qweqw'}
                            id={value.id}
                        />
                    ))}
                </div>

                <div className={'w-1/2 bg-[#f9fafc] relative rounded-3xl shadow-lg overflow-y-auto p-6 flex flex-col gap-4'}>
                    <div className={'text-2xl font-bold mb-4 text-center text-red-600'}>Buyurtma</div>

                    <Collapse activeKey={activeKey} onChange={handleCollapseChange} accordion bordered={false}>
                        <Panel header="Qo'shimcha manzillar" key="1" className="custom-collapse-panel">
                            {additionalAddresses.length > 2 ? (
                                <Collapse>
                                    <Panel header={`Hamma manzillarni ko'rsatish (${additionalAddresses.length})`} key="1-1" className="custom-sub-collapse-panel">
                                        {additionalAddresses.map((addr, index) => (
                                            <div key={index} className={`flex items-center justify-between mt-2 p-2 rounded-lg ${index === selectedAddressIndex ? 'bg-green-100' : ''}`}>
                                                <span className="cursor-pointer text-green-700" onClick={() => handleSelectAddress(addr, index)}>
                                                    {addr}
                                                </span>
                                                <div className="flex items-center">
                                                    <Button
                                                        type="link"
                                                        icon={<HiOutlinePencil className="text-green-500" />}
                                                        onClick={() => showEditModal(addr, index)}
                                                    />
                                                    <Popconfirm
                                                        title="Bu manzilni o'chirishga ishonchingiz komilmi?"
                                                        onConfirm={() => handleDeleteAddress(index)}
                                                        okText="Ha"
                                                        cancelText="Yo'q"
                                                    >
                                                        <Button
                                                            type="link"
                                                            danger
                                                            icon={<HiOutlineTrash className="text-green-500" />}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        ))}
                                    </Panel>
                                </Collapse>
                            ) : (
                                additionalAddresses.map((addr, index) => (
                                    <div key={index} className={`flex items-center justify-between mt-2 p-2 rounded-lg ${index === selectedAddressIndex ? 'bg-red-100' : ''}`}>
                                        <span className="cursor-pointer text-green-600" onClick={() => handleSelectAddress(addr, index)}>
                                            {addr}
                                        </span>
                                        <div className="flex items-center">
                                            <Button
                                                type="link"
                                                icon={<HiOutlinePencil className="bg-green-600" />}
                                                onClick={() => showEditModal(addr, index)}
                                            />
                                            <Popconfirm
                                                title="Bu manzilni o'chirishga ishonchingiz komilmi?"
                                                onConfirm={() => handleDeleteAddress(index)}
                                                okText="Ha"
                                                cancelText="Yo'q"
                                            >
                                                <Button
                                                    type="link"
                                                    danger
                                                    icon={<HiOutlineTrash className="text-green-500" />}
                                                />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ))
                            )}

                            <div className="mt-4">
                                <Input
                                    value={currentAddress}
                                    onChange={(e) => setCurrentAddress(e.target.value)}
                                    placeholder="Yangi manzilni kiriting"
                                    className="rounded-lg mb-4"
                                />
                                <Button type="primary bg-green-400" onClick={handleAddNewAddress}>
                                    Yangi manzil qo'shish
                                </Button>
                            </div>

                            <Button type="default bg-blue-500 text-white mt-4" onClick={handleResetToDefaultAddress}>
                                Asosiy manzilga qaytish
                            </Button>
                        </Panel>

                        <Panel header="Eshikni taqillatish" key="2" className="custom-collapse-panel">
                            <div className={'w-full mb-2 text-red-600 font-semibold'}>Eshikni taqillatish</div>
                            <div className={'flex flex-row items-center mb-4'}>
                                <HiHome className={'text-xl mr-2 bg-red-400 text-white rounded p-1'} />
                                <Radio.Group onChange={handleKnockChange} value={knockChoice}>
                                    <Radio.Button value="ha" className="custom-radio-button">
                                        Ha
                                    </Radio.Button>
                                    <Radio.Button value="yoq" className="custom-radio-button">
                                        Yo'q
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </Panel>

                        <Panel header="To'lov turi" key="3" className="custom-collapse-panel">
                            <div className={'w-full mb-2 text-red-600 font-semibold'}>To'lov turi</div>
                            <div className={'flex flex-row items-center'}>
                                <Radio.Group onChange={handlePaymentChange} value={paymentType}>
                                    <Radio.Button value="naqd" className="custom-radio-button">
                                        <div className={'flex flex-row items-center'}>
                                            <HiCash className="mr-2 text-red-600" /> Naqd pul
                                        </div>
                                    </Radio.Button>
                                    <Radio.Button value="karta" className="custom-radio-button">
                                        <div className={'flex flex-row items-center'}>
                                            <HiCreditCard className="mr-2 text-red-600" /> Karta
                                        </div>
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </Panel>

                        <Panel header="Qulay vaqt" key="4" className="custom-collapse-panel">
                            <div className={'w-full mb-2 mt-2 text-red-600 font-semibold'}>Qulay vaqt</div>
                            <div className={'flex flex-row items-center'}>
                                <TimePicker
                                    use12Hours
                                    format="HH:mm"
                                    onChange={handleTimeChange}
                                    value={selectedTime}
                                    placeholder="Vaqtni tanlang"
                                    className="w-full"
                                />
                            </div>
                        </Panel>
                    </Collapse>

                    <div className={'w-full mt-4'}>
                        <Input.TextArea
                            rows={3}
                            placeholder="Buyurtma uchun qo'shimcha ma'lumot kiriting"
                            value={customDescription}
                            onChange={(e) => setCustomDescription(e.target.value)}
                            className="rounded-lg"
                        />
                    </div>

                    <div className={'w-full h-36 flex flex-col absolute bottom-0 left-0 px-3'}>
                        <div className={'w-full flex items-center justify-between'}>
                            <div className={'font-bold text-black/70'}>Jami</div>
                            <div className={'font-bold text-black/70'}>{totalPrice} so'm</div>
                        </div>
                        <div
                            className={'cursor-pointer w-full h-16 flex items-center justify-center rounded-full bg-red-600 mt-3 text-center text-white transition duration-300 hover:bg-red-700'}
                            onClick={confirmOrder}
                        >
                            <div className={'text-2xl font-bold'}>Davom ettirish</div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title="Manzilni tahrirlash"
                visible={isEditModalVisible}
                onOk={handleEditAddress}
                onCancel={() => setIsEditModalVisible(false)}
                okText="Saqlash"
                cancelText="Bekor qilish"
                className="custom-modal"
            >
                <Form form={form} layout="vertical" name="edit_address_form">
                    <Form.Item
                        name="address"
                        label="Manzil"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos, manzilni kiriting",
                            },
                        ]}
                    >
                        <Input className="rounded-lg" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
