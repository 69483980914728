import React, {useEffect, useState} from 'react';
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import Multiple_Product_Card from "../Multiple_Product_Card/Multiple_Product_Card";

import 'swiper/css';

import 'swiper/css/navigation';

import nextIcon from '../assets/img/icons/nextArrow.svg';
import prevIcon from '../assets/img/icons/prevArrow.svg';
import {useDispatch} from "react-redux";
import {setItemType} from "../store/actions/selectedItemTypeAction";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {fetchCategoriesById, fetchCategoriesType} from "../../Api/Categories/Categories";
import {findSliderCategory} from "../../utils";
import {addToHistory} from "../store/actions/historyAction";
import {addToCategory} from "../store/actions/categoryAction";

export default function Multiple_Product_Card_Swiper() {

    const dispatch = useDispatch()


   const  [slider,setSlider] = useState();

    useEffect(()=>{
        fetchSlider()
    },[])

    const fetchSlider = async ()=>{
        const data = await fetchCategoriesType('uz');
        dispatch(addToCategory(data))
        const myId =  findSliderCategory(data)
        const slide =await  fetchCategoriesById('uz',myId[0].categories[0].id)
        setSlider(slide.data.products)
        console.log(slide.data.products)



    }

    return<>
        <div className={'w-full h-72 flex items-center gap-3 relative'}>

            {/*There need Swiper*/}

            <button className={`swiper-button image-swiper-button-prev__ max-[1000px]:hidden absolute z-10 top-[40%] left-0`}>

                {/*<IoIosArrowBack/>*/}
                <img src={prevIcon} className={'w-fit h-20 object-contain'}/>

            </button>
            <button className={`swiper-button image-swiper-button-next__ max-[1000px]:hidden absolute z-10 top-[40%] right-0 text-3xl text-white`}>

                {/*<IoIosArrowForward/>*/}
                <img src={nextIcon} className={'w-fit h-20 object-contain'}/>

            </button>
            <Swiper
                navigation={{
                    nextEl: ".image-swiper-button-next__",
                    prevEl: ".image-swiper-button-prev__",
                    disabledClass: "swiper-button-disabled"
                }}
                modules={[Navigation , Autoplay]}
                loop={true}
                className={'w-full h-full'}
                spaceBetween={10}
                breakpoints={{
                    // когда ширина экрана больше 1024px
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    // когда ширина экрана больше 1280px
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    // когда ширина экрана больше 1440px
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    },
                    // когда ширина экрана больше 1918px
                    1918: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    }
                }}
            >

                {slider?.map((value, index) => <SwiperSlide key={index} className={'w-56 h-full'}>
                    <Multiple_Product_Card onClick={()=>{
                        dispatch(setItemType('Stories'))
                        dispatch(setDrawerOpen(true))
                        dispatch(addToHistory(value))
                        console.log(value)
                    }} cover={value.cover} title={value.name}/>
                </SwiperSlide>)}

            </Swiper>

        </div>
    </>
}
