import React, { useEffect, useState } from "react";
import style from "./style.module.css";

import Stories from 'react-insta-stories';
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { filterCategories, replaceUrlsAndCategories, transformCoverArray } from "../../../../utils";
import {fetchCategoriesById} from "../../../../Api/Categories/Categories";
import {message} from "antd";
import {selectDrawerOpen} from "../../../../components/store/selectors/drawerSelector";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import {setDrawerOpen} from "../../../../components/store/actions/drawerAction";
import {setSelectedProducts} from "../../../../components/store/actions/selectedProductActions";
import {setItemType} from "../../../../components/store/actions/selectedItemTypeAction";


export default function SelectedStoryContentMobile() {

    const dispatch = useDispatch()
    const history = useSelector(state => state.history);
    const categories = useSelector(state => state.categories);
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const [filteredCategories, setFilteredCategories] = useState([]);

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const filteredCategories = filterCategories(categories.categories, replaceUrlsAndCategories(history.history.description).categories);

            const filteredCategoryIdsWithProducts = filteredCategories.filter(category => category?.categories?.length > 0);

            const fetchedCategories = await Promise.all(filteredCategoryIdsWithProducts.map(async (category) => {
                const categoryData = await fetchCategoriesById('uz', category.id);

                return categoryData.data
            }));
            setFilteredCategories(fetchedCategories);

        };
        fetchData();
    }, [history])

    return (
        <div  >
            <div className={'w-full h-full flex relative z-0 flex-col items-start overflow-scroll'}>
                <div className={'w-full h-[400px] rounded-3xl overflow-hidden '}>

                    <Stories
                        stories={history?.history?.cover ? transformCoverArray(history.history.cover) : []}
                        defaultInterval={5000}
                        width={'100%'}
                        height={'100%'}
                    />

                </div>

                <div className={style.container}>
                    <div className={'flex flex-col gap-2 items-start'}>
                        <p className={'text-xl font-bold'}>{history?.history?.name}</p>
                        <div className={'text-balance text-black/60 font-semibold'}
                             dangerouslySetInnerHTML={{ __html: replaceUrlsAndCategories(history?.history.description).description }}></div>
                    </div>

                    <div className={'w-full flex items-center flex-wrap gap-2 justify-start sticky top-0 z-20 py-2 bg-[#ffffff] mt-2'}>
                        {replaceUrlsAndCategories(history?.history?.description).categories.map((value, index) => (
                            <Link to={'#'} className={'px-2 py-1 rounded-full bg-[#f2f2f2] text-xs font-bold text-black/60'} style={{ textDecoration: 'none' }}>{value}</Link>
                        ))}
                    </div>

                    {filteredCategories.map(category => (
                        <div key={category.id}>
                            {category && category.products && category.products.length > 0 && ( // Check if category and its products exist and have length > 0
                                <>
                                    <span className={'text-xl font-semibold '}>{category.name}</span>
                                    <div className={'w-full h-auto flex flex-row flex-wrap justify-center items-start gap-4 mt-4 '}>
                                        {category.products.map((product, index) => (
                                            <ProductCard
                                                onClick={() => {
                                                    dispatch(setDrawerOpen(true));
                                                    dispatch(setSelectedProducts(product));
                                                    dispatch(setItemType("saleProduct"));
                                                }}
                                                key={index}
                                                cover={product.cover}
                                                percentPromo={0}
                                                capacity={product.brief_description}
                                                title={product.name}
                                                oldPrice={'1000'}
                                                price={product.price}
                                                id={product.id}
                                                badgeText={product.brands}
                                                initialState={getProductCount(product.id)}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
}
