import {ADD_TO_TOTAL_PRICE, SUBTRACT_FROM_TOTAL_PRICE} from "../actions/priceAction";

const initialState = {
    totalPrice: 0
};

const priceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_TOTAL_PRICE:
            return {
                ...state,
                totalPrice: state.totalPrice + action.payload
            };
        case SUBTRACT_FROM_TOTAL_PRICE:
            return {
                ...state,
                totalPrice:  (state.totalPrice - action.payload) <0 || (state.totalPrice - action.payload) === 0 ? 0 : state.totalPrice - action.payload
            };
        default:
            return state;
    }
};

export default priceReducer;
