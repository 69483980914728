const initialState = {
    itemType : ''
}

export const selectedItemTypeReducer = (state = initialState ,action) => {
    switch (action.type) {
        case 'SET_ITEM_TYPE':
            return {...state, itemType : action.payload}
        default :
            return state;
    }
}