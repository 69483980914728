import React, {useState} from "react";
import style from "./style.module.css";
import {TbLocation} from "react-icons/tb";
import {IoClose} from "react-icons/io5";
import {FaPlus} from "react-icons/fa";

export default function LocationContent() {

    const [searchActive,setSearchActive] = useState(true)
    const [activeCity,setCityActive] = useState(false)
    const [searchText,setSearchText] = useState("")
    const [selectedCity,setSelectedCity] = useState("")
    const [address,setAddress] = useState("")
    const [addressActive,setAddressActive] = useState(true)

    const cities = [
        "Москва",
        "Санкт-Петербург",
        "Альметьевск",
        "Астрахань",
        "Балаково",
        "Барнаул",
        "Белгород",
        "Брянск",
        "Великий Новгород",
        "Владимир",
        "Волгоград",
        "Вологда",
        "Воронеж",
        "Геленджик",
        "Екатеринбург",
        "Иваново"
    ];

    const hoods = [
        "Москва",
        "Санкт-Петербург",
        "Альметьевск",
        "Астрахань",
        "Балаково",
        "Барнаул",
        "Белгород",
        "Брянск",
        "Великий Новгород",
        "Владимир",
        "Волгоград",
        "Вологда",
        "Воронеж",
        "Геленджик",
        "Екатеринбург",
        "Иваново"
    ];

    const FilteredCities = cities.filter(city=>
        city.toLowerCase().includes(searchText.toLowerCase())
    )

    const FilteredHoods = hoods.filter(hood=>
        hood.toLowerCase().includes(address.toLowerCase())
    )

    return<>
        <div className={'w-full h-full flex items-start '}>

            <div className={'w-1/2 h-full rounded-3xl relative overflow-hidden'}>

                <div className={'absolute top-3 right-3 rounded-full text-xl bg-[#ffffff] p-2 flex items-center justify-center'}>
                    <TbLocation />
                </div>

                <img className={'w-full h-full object-cover'} src={'https://upload.wikimedia.org/wikipedia/commons/7/7c/2018_Nissan_GT-R_Premium_in_Super_Silver%2C_Front_Right%2C_10-11-2022.jpg'} alt={''}/>

            </div>

            <div className={style.container}>

                <div className={'w-full bg-[#ffffff] sticky top-0 z-10 flex items-center text-2xl px-6 py-4 font-bold'}>

                    <span>Добавить адрес</span>

                </div>

                {activeCity?
                    <div className={'w-full h-auto gap-2 pl-6'}>
                        <div className={'w-full h-10 flex items-center gap-2 relative text-black/60 border-b select-none'}>
                            <div className={'text-xl font-bold '}>{selectedCity}</div>
                        </div>

                        {addressActive? <div className={'w-full h-auto relative select-none'}>
                            <input onChange={(e)=>setAddress(e.target.value)} placeholder={'Адрес'} className={'w-full h-10 outline-0 border-b pl-4 '}/>
                            <IoClose onClick={()=>setAddressActive(false)} className={'cursor-pointer text-xl absolute top-2 right-2'} />
                        </div> : <div onClick={()=>setAddressActive(true)} className={'w-full h-10 flex items-center gap-2 relative text-black/60 border-b select-none'}>
                            <FaPlus  className={'cursor-pointer text-xl translate-y-0.5'} />
                            <div className={'text-xl font-bold '}>Улица и Дом</div>
                        </div>}

                        <div className={'w-full h-auto flex flex-col items-start gap-1 mt-2'}>

                            {FilteredHoods?.map((value, index)=> <div onClick={()=>{
                                setAddressActive(false)
                                setAddress(value)
                            }} key={index} className={'cursor-pointer duration-200 hover:text-black/40 text-xl text-black/80 font-semibold'}>{value}</div>)}

                        </div>

                    </div>

                     :
                    <div className={'w-full h-auto flex flex-col items-start pl-6'}>

                        {searchActive? <div className={'w-full h-auto relative select-none'}>
                            <input onChange={(e)=>setSearchText(e.target.value)} placeholder={'Город'} className={'w-full h-10 outline-0 border-b pl-4 '}/>
                            <IoClose onClick={()=>setSearchActive(false)} className={'cursor-pointer text-xl absolute top-2 right-2'} />
                        </div> : <div onClick={()=>setSearchActive(true)} className={'w-full h-10 flex items-center gap-2 relative text-black/60 border-b select-none'}>
                            <FaPlus  className={'cursor-pointer text-xl translate-y-0.5'} />
                            <div className={'text-xl font-bold '}>Город</div>
                        </div>}

                        <div className={'w-full h-auto flex flex-col items-start gap-1 mt-2'}>

                            {FilteredCities?.map((value, index)=> <div onClick={()=>{
                                setCityActive(true)
                                setSelectedCity(value)
                            }} key={index} className={'cursor-pointer duration-200 hover:text-black/40 text-xl text-black/80 font-semibold'}>{value}</div>)}

                        </div>

                    </div>
                }


            </div>

        </div>
    </>
}