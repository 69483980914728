import React from "react";
import { Typography, Card, Avatar, Row, Col, Tag, Button } from "antd";
import { AiOutlineUser, AiOutlineHome, AiOutlinePhone, AiOutlineLogout } from "react-icons/ai";
import { format } from "date-fns";
import MapContainer from "../../../MapContainer/MapContainer";

export default function Profile() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const isTelegramRegistered = profile.telegram_id && !profile.telegram_id.toLowerCase().startsWith('unknown');

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <Card className="w-full h-full flex flex-col items-start p-6 bg-gray-100 shadow-md rounded-lg">
            <Row gutter={[16, 16]} className="w-full">
                <Col span={24} className="flex flex-row items-center gap-3">
                    <Avatar  size={44} className="bg-blue-400  h-[30px]" style={{ borderRadius: '50%' }}>
                        <AiOutlineUser className="text-2xl text-white" />
                    </Avatar>
                    <div>
                        <Typography.Title level={4} className="mb-0">{profile.fullname}</Typography.Title>
                        {isTelegramRegistered && (
                            <Tag color="green">Вы зарегистрированы в Telegram</Tag>
                        )}
                        <Typography.Text type="secondary">
                            Зарегистрирован: {format(new Date(profile.created_at), 'dd MMMM yyyy')}
                        </Typography.Text>
                    </div>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={12} className="flex items-center">
                            <AiOutlineHome className="text-xl text-blue-400 mr-2" />
                            <Typography.Text>{profile.address || "Адрес не указан"}</Typography.Text>
                        </Col>
                        <Col span={12} className="flex items-center">
                            <AiOutlinePhone className="text-xl text-blue-400 mr-2" />
                            <Typography.Text>{profile.phoneNumber || "Телефон не указан"}</Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className={'h-[500px] overflow-scroll'}>
                    <MapContainer/>
                </Col>
                <Col span={24} className="mt-4">
                    <Button className={'w-full'} type="primary" danger icon={<AiOutlineLogout />} onClick={handleLogout}>
                        Выйти из профиля
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
