import React from "react";

export default function Multiple_Product_Card({cover, title, onClick}) {

    return<>
        <div onClick={onClick} className={'cursor-pointer w-full h-full bg-black rounded-3xl flex flex-col relative overflow-hidden '}>
            <img className={'w-full h-full object-cover absolute top-0 left-0 z-0 '}
                 src={'https://api.osonexpress.uz/'+ cover[0]} alt={title}/>

            <div
                className={'z-10 w-full h-full flex flex-col items-start p-5 bg-gradient-to-b from-black/50 to-transparent'}>
                <div
                    className={'text-white text-xl font-semibold line-clamp-3'}>{title}</div>
            </div>
        </div>
    </>
}
