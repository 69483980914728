import React, {useEffect, useState} from "react";
import style from "./style.module.css";
import {Link} from "react-router-dom";
import {ON_SALE_PRODUCTS} from "../../../../CustomData/CustomData";
import ProductCard from "../../../ProductCard/ProductCard";
import {setDrawerOpen} from "../../../store/actions/drawerAction";
import {setSelectedProducts} from "../../../store/actions/selectedProductActions";
import {setItemType} from "../../../store/actions/selectedItemTypeAction";
import {useDispatch, useSelector} from "react-redux";
import {selectDrawerOpen} from "../../../store/selectors/drawerSelector";
import {fetchProducts} from "../../../../Api/Products/products";
import fetchCategories, {fetchCategoriesById} from "../../../../Api/Categories/Categories";

export default function SelectedPromoContent() {

    const dispatch = useDispatch()
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const promoData = useSelector(state => state.promo.promo);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };
    useEffect(() => {
        fetchCategoriesApi()
    }, [isDrawerOpen, promoData])
    const fetchCategoriesApi = async () => {
        const data = await fetchCategoriesById('uz', promoData.id)
        const cat = await fetchCategories('uz')
        console.log(cat)
        setCategories(cat.categories)
        console.log(data.data)
        setProducts(data.data.products)
    }
    const Categories_offer = [
        "Для сэндвича",
        "Хлебцы и хумус",
        "Кефир и ряженка",
        "Творог",
        "Йогурты и творожки",
        "Шоколад и батончики",
        "Орехи и сухофрукты",
        "Снеки",
        "Уже готово"
    ];

    return <>
        <div className={style.container}>

            <div className={'w-full flex items-start gap-3 '}>

                <div className={'w-36 h-40 bg-[#e5f0d0] block rounded-lg overflow-hidden relative shadow'}>

                    <img className={'w-full h-full object-contain'}
                         src={`https://api.osonexpress.uz/${products[products?.length-1]?.cover[0]}`}
                         alt={''}/>


                </div>

                <div className={'flex flex-col gap-2 items-start'}>

                    <p className={'text-2xl font-bold'}> {promoData?.name}</p>

                    <p className={'text-balance font-semibold'}>{promoData?.brief_description}</p>

                </div>

            </div>

            {/*<div className={'w-full flex items-center flex-wrap gap-2 justify-start mt-4'}>*/}

            {/*    {Categories_offer.map((value, index) => <Link to={'#'}*/}
            {/*                                                  className={'px-3 py-1 rounded-full bg-[#f2f2f2] text-sm font-bold text-black/60'}>{value}</Link>)}*/}

            {/*</div>*/}

            <div
                className={'w-full h-auto flex flex-wrap max-[1000px]:justify-center justify-start items-start gap-3 mt-6 '}>

                {products.map((product, index) => <ProductCard onClick={() => {
                    dispatch(setDrawerOpen(true))
                    dispatch(setSelectedProducts(product))
                    dispatch(setItemType("saleProduct"))
                }} key={index}
                                                               cover={product.cover}
                                                               percentPromo={0}
                                                               capacity={product.brief_description}
                                                               title={product.name}
                                                               oldPrice={'1000'}
                                                               price={product.price}
                                                               id={product.id}
                                                               badgeText={product.brands}
                                                               initialState={getProductCount(product.id)}/>)}

            </div>

        </div>
    </>
}
