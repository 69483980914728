import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import { setDrawerOpen } from "../store/actions/drawerAction";
import style from "./style.module.css";
import SelectedProductContent from "./components/SelectedProductContent/SelectedProductContent";
import SelectedPromoContent from "./components/SelectedPromoContent/SelectedPromoContent";
import SelectedStoryContent from "./components/SelectedStoryContent/SelectedStoryContent";
import LocationContent from "./components/LocationContent/LocationContent";
import PaymentContent from "./components/PaymentContent/PaymentContent";
import LoginContent from "./components/LoginContent/LoginContent";



export default function CustomDrawer({ open, onClose }) {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    const itemType = useSelector((state)=>state.selectedItemType.itemType)

    useEffect(() => {
        if (open === true) {
            setOpen(true);
            document.body.style.overflow = "hidden"; // Запретить прокрутку body
        }
    }, [open]);

    const handleClose = () => {
        setTimeout(() => {
            dispatch(setDrawerOpen(false));
            document.body.style.overflow = ""; // Разрешить прокрутку body
        }, 500);
    };

    console.log(itemType)

    return (
        <>
            <div onClick={()=>{
                setOpen(false)
                handleClose()
            }}
                className={`${
                    open ? "block" : "hidden"
                } w-full h-full fixed top-0 left-0 flex items-center justify-end p-8 z-[1000] max-[1000px]:hidden ${isOpen ? style.container : style.containerOut} `}
            >
                <div className={'w-full h-full relative flex items-center justify-end'}>
                    <div className={'w-full h-full absolute top-0 left-0 flex items-center justify-end'}>

                        <div onClick={(e) => e.stopPropagation()} className={` ${itemType === 'Login' ? `w-[450px]` : `w-[900px]`} ${isOpen? style.wrapperIn : style.wrapperOut} `}>

                            <div className={`w-full h-full flex ${itemType === 'Payment'? `bg-[#f2f2f2]` :``} rounded-3xl items-start p-6 relative`}>
                                {/*Close Button*/}
                                <div onClick={()=>{
                                        setOpen(false)
                                        handleClose()
                                    }} className={` ${itemType === 'Payment'? `bg-[#ffffff]` :`bg-[#f2f2f2]`} cursor-pointer p-2 rounded-full  absolute right-4 top-4 text-2xl shadow z-30`}>
                                    <IoMdClose />
                                </div>

                                {/*Content Place*/}
                                {itemType === 'saleProduct'? <SelectedProductContent/> : ``}
                                {itemType === 'Promo'? <SelectedPromoContent/> : ``}
                                {itemType === 'Stories'? <SelectedStoryContent/> : ``}
                                {itemType === 'Location'? <LocationContent/> : ``}
                                {itemType === 'Payment'? <PaymentContent/> : ``}
                                {itemType === 'Login'? <LoginContent/> : ``}

                            </div>


                        </div>

                    </div>



                </div>

            </div>
        </>
    );
}
