import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { message } from "antd";
import { Link } from "react-router-dom";

export default function DropdownMenu({ value, array }) {
    const [isOpen, setOpen] = useState(false);
    const [isActive, setActive] = useState(false);

    return (
        <div className={'w-full h-auto flex flex-col items-start'}>
            <div onClick={() => setOpen(!isOpen)} onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)} className={'w-full flex items-center h-10 gap-3 cursor-pointer select-none '}>
                <div className={` ${isOpen ? `` : isActive ? 'brightness-75' : ``} duration-300 flex flex-col items-center justify-center h-full relative rounded-md bg-[#f2f2f2]`}>
                    {value && value?.icon && <img className={` h-full object-contain w-fit rounded-md`} src={'https://api.osonexpress.uz/' + value?.icon} alt={value?.name} />}
                    <div className={` ${isOpen ? isActive ? `` : `opacity-0` : `opacity-0`} duration-300 absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#f2f2f2]/50 rounded-md `}>
                        <FaChevronDown className={'text-xl '} />
                    </div>
                </div>
                <span className={'text-black/80 text-balance font-semibold line-clamp-1 '}>{value?.name}</span>
            </div>
            <ul style={{ height: `${isOpen ? array.length * 32 : 0}px` }} className={`duration-300 w-full text-center overflow-hidden items-end bg-gray-200 rounded-lg mt-2`}>
                {array?.map((value2, index) => (
                    <Link key={index} to={`/products/${value2?.id}/${value2?.name}/${value?.name}/${value?.id}`} style={{ textDecoration: 'none' }}>
                        <li className={'h-8 text-black/80 text-balance font-semibold line-clamp-3 hover:bg-gray-200 cursor-pointer flex flex-row items-end p-1 '}>
                            {value2 && value2?.icon && <img className={`h-[25px] object-contain w-[25px] mr-2 rounded`} src={'https://api.osonexpress.uz/' + value2?.icon?.icon} alt={value2?.name} />}
                            {value2?.name}
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
}
