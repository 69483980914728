import example from "../components/assets/img/products/exampleProduct.png";
import logo from "../components/assets/img/logo/food_logo.png";



export const FOOTER_LINKS =  [
    "Ответы на вопросы",

]

export const CATEGORIES = [
    {
        cover: logo,
        title: `На разные случаи`,
        links : [
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
        ]
    },
    {
        cover: logo,
        title: `На разные случаи`,
        links : [
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
        ]
    },
    {
        cover: logo,
        title: `На разные случаи`,
        links : [
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
            {
                title : 'Lorem ipsum',
                to: ''
            },
        ]
    },
]

export const ON_SALE_PRODUCTS = [
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
    {
        title: 'Burger',
        cover: example,
        images: [
            example,
            example,
            example,
        ],
        percentPromo: '15',
        price: '15000',
        oldPrice: '20000',
        capacity: '120 g',
        badgeText: 'New',
        productQuantity: '20',
        information : [
            {
                benefits : [
                    'В индейке много белка и мало жира',
                    'Можно не чистить',
                    'Варить или жарить 3 минуты',
                    'Упаковку можно сдать на переработку',
                ],
                description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                per_100_gr_title : 'В 100 граммах',
                per_100_gr: [
                    {
                        title: "Ккал",
                        value: "125,5 Ккал",
                    },
                    {
                        title: "Белки",
                        value: "8 г",
                    },
                    {
                        title: "Жиры",
                        value: "9,5 г",
                    },
                    {
                        title: "Углеводы",
                        value: "2 г",
                    },
                ],
                ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                shelf_life : '1 месяц',
                storage_conditions : 'При температуре от +2°C до +6°C',
                manufacturer : 'ООО Пятачок, Россия',
            }

        ]
    },
]


export const PROMO_CARDS = [
    {
        type: 'danger',
        cover: example,
        title: 'Lorem ipsum dolor sit amet.'
    },
    {
        type: 'new',
        cover: example,
        title: 'Lorem ipsum dolor sit amet.'
    },
    {
        type: 'popular',
        cover: example,
        title: 'Lorem ipsum dolor sit amet.'
    },
    {
        type: 'danger',
        cover: example,
        title: 'Lorem ipsum dolor sit amet.'
    },
]



export const DIFFERENT_OFFERS = [
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
    {
        title: 'Что нового',
        cover: example,
    },
]

export const OFFERS_BY_CATEGORY = [
    {
        title: 'Что нового',
        cover: example,
        to:'burger'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'kfc'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'lays'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'strips'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'shashlik'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'apple'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'banana'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'chips'
    },
    {
        title: 'Что нового',
        cover: example,
        to:'soda'
    },
]

export const RECOMENDATION = [
    {
        title: "Чего ещё пригодится",
        products : [
            {
                title: 'Burger',
                cover: example,
                images: [
                    example,
                    example,
                    example,
                ],
                percentPromo: '15',
                price: '153000',
                oldPrice: '20000',
                capacity: '120 g',
                badgeText: 'New',
                productQuantity: '20',
                information : [
                    {
                        benefits : [
                            'В индейке много белка и мало жира',
                            'Можно не чистить',
                            'Варить или жарить 3 минуты',
                            'Упаковку можно сдать на переработку',
                        ],
                        description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                        per_100_gr_title : 'В 100 граммах',
                        per_100_gr: [
                            {
                                title: "Ккал",
                                value: "125,5 Ккал",
                            },
                            {
                                title: "Белки",
                                value: "8 г",
                            },
                            {
                                title: "Жиры",
                                value: "9,35 г",
                            },
                            {
                                title: "Углеводы",
                                value: "2 г",
                            },
                        ],
                        ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                        shelf_life : '12 месяц',
                        storage_conditions : 'При температуре от +2°C до +6°C',
                        manufacturer : 'ООО Пятачок, Россия',
                    }

                ]
            },
            {
                title: 'Burger',
                cover: example,
                images: [
                    example,
                    example,
                    example,
                ],
                percentPromo: '15',
                price: '15000',
                oldPrice: '20000',
                capacity: '120 g',
                badgeText: 'New',
                productQuantity: '20',
                information : [
                    {
                        benefits : [
                            'В индейке много белка и мало жира',
                            'Можно не чистить',
                            'Варить или жарить 3 минуты',
                            'Упаковку можно сдать на переработку',
                        ],
                        description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                        per_100_gr_title : 'В 100 граммах',
                        per_100_gr: [
                            {
                                title: "Ккал",
                                value: "125,5 Ккал",
                            },
                            {
                                title: "Белки",
                                value: "28 г",
                            },
                            {
                                title: "Жиры",
                                value: "9,5 г",
                            },
                            {
                                title: "Углеводы",
                                value: "32 г",
                            },
                        ],
                        ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                        shelf_life : '1 месяц',
                        storage_conditions : 'При температуре от +2°C до +6°C',
                        manufacturer : 'ООО Пятачок, Россия',
                    }

                ]
            },
            {
                title: 'Burger',
                cover: example,
                images: [
                    example,
                    example,
                    example,
                ],
                percentPromo: '125',
                price: '15000',
                oldPrice: '20000',
                capacity: '120 g',
                badgeText: 'New',
                productQuantity: '20',
                information : [
                    {
                        benefits : [
                            'В индейке много белка и мало жира',
                            'Можно не чистить',
                            'Варить или жарить 3 минуты',
                            'Упаковку можно сдать на переработку',
                        ],
                        description: "Диетические сосиски из индейки с добавлением пряностей. Сочные, с мягким вкусом и нежной текстурой. Отварить или пожарить сосиски можно за несколько минут, поэтому они подойдут для быстрого перекуса.",
                        per_100_gr_title : 'В 100 граммах',
                        per_100_gr: [
                            {
                                title: "Ккал",
                                value: "125,5 Ккал",
                            },
                            {
                                title: "Белки",
                                value: "8 г",
                            },
                            {
                                title: "Жиры",
                                value: "9,5 г",
                            },
                            {
                                title: "Углеводы",
                                value: "2 г",
                            },
                        ],
                        ingredients : 'Филе индейки, вода питьевая, соль морская, регулятор кислотности лимонная кислота, натуральные специи, коллагеновая оболочка из говяжьего спилка. Продукт может содержать следы молока, яиц, горчицы, сои, злаков, орехов и продукты их переработки',
                        shelf_life : '1 месяц',
                        storage_conditions : 'При температуре от +2°C до +6°C',
                        manufacturer : 'ООО Пятачок, Россия',
                    }

                ]
            }
        ]
    },
]
