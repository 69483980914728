const initialState = {
    drawer : false
}

export const drawerReducer = (state = initialState ,action) => {
    switch (action.type) {
        case 'SET_DRAWER':
            return {...state, drawer : action.payload}
        default :
            return state;
    }
}