import React, { useCallback, useEffect, useState } from "react";
import style from "../../../../components/CustomDrawer/components/PaymentContent/style.module.css";
import SelectedProduct from "../../../../components/SelectedProduct/SelectedProduct";
import { setDrawerOpen } from "../../../../components/store/actions/drawerAction";
import { setSelectedProducts } from "../../../../components/store/actions/selectedProductActions";
import { setItemType } from "../../../../components/store/actions/selectedItemTypeAction";
import { HiHome, HiOutlinePencil, HiOutlineTrash, HiCash, HiCreditCard } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectDrawerOpen } from "../../../../components/store/selectors/drawerSelector";
import { selectProducts } from "../../../../components/store/selectors/productSelectors";
import { addToTotalPrice, subtractFromTotalPrice } from "../../../../components/store/actions/priceAction";
import { filterProducts } from "../../../../utils";
import { Input, message, Button, Modal, Form, Popconfirm, Radio, TimePicker, Collapse } from "antd";
import { useTelegram } from "../../../../hooks/TelegramHooh";
import axios from "axios";
import dayjs from "dayjs";
import { makeOrder } from "../../../../Api/order/orderApi";

const { Panel } = Collapse;

export default function Mob_PaymentContent() {
    const dispatch = useDispatch();
    const [backet, setBacket] = useState([]);
    const pom = useParams();
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const [currentPrice, setCurrentPrice] = useState(0);
    const totalPrice = useSelector(state => state.price.totalPrice);
    const { tg, user, queryId } = useTelegram();
    const [isTgUser, setTgUser] = useState(false);
    const [currentAddress, setCurrentAddress] = useState("");
    const userAddress = localStorage.getItem('address') || 'Адрес не установлен';
    const [additionalAddresses, setAdditionalAddresses] = useState(
        JSON.parse(localStorage.getItem("additionalAddresses")) || []
    );
    const [paymentType, setPaymentType] = useState("Naqd pul");
    const [knockChoice, setKnockChoice] = useState("Ha");
    const [selectedTime, setSelectedTime] = useState(dayjs());
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editAddressIndex, setEditAddressIndex] = useState(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [form] = Form.useForm();

    const confirmOrder = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            let basket = JSON.parse(localStorage.getItem('basket'));

            basket.products = basket.products.filter(product => product.product_id !== 0 && product.count > 0);

            const description = `
                To'lov turi: ${paymentType}, 
                Eshikni taqillatish: ${knockChoice}, 
                Yetkazib berish vaqti: ${selectedTime ? selectedTime.format('HH:mm') : 'Ko\'rsatilmagan'}
            `;

            const orderData = {
                user_id: userId,
                address: currentAddress || userAddress,
                products: basket.products,
                description: description
            };

            const response = await makeOrder(orderData);
            localStorage.removeItem('basket');
            basket = { products: [{ product_id: 0, count: 0 }] };
            localStorage.setItem('basket', JSON.stringify(basket));
            dispatch(subtractFromTotalPrice(currentPrice));
            message.success("Buyurtma muvaffaqiyatli yaratildi!");
            return response;
        } catch (error) {
            console.error('Buyurtma yaratishda xatolik yuz berdi:', error);
            message.error('Buyurtma yaratishda xatolik yuz berdi');
        }
    };



    useEffect(() => {
    }, [totalPrice]);

    const products = useSelector(selectProducts);

    const handleAddToTotalPrice = (price) => {
        dispatch(addToTotalPrice(parseInt(price)));
    };



    useEffect(() => {
        setBacket(filterProducts(products?.products, JSON.parse(localStorage.getItem('basket'))));
        let t = 0;
        filterProducts(products?.products, JSON.parse(localStorage.getItem('basket'))).forEach(value => t += parseInt(value.price) * value.count);
        if (totalPrice === 0) {
            setCurrentPrice(t);
            handleAddToTotalPrice(t);
        } else {
            setCurrentPrice(totalPrice);
        }

        try {
            if (user?.id?.toString().length > 0) {

                setTgUser(true);
            } else {
                setTgUser(false);
            }
        } catch (e) {
            console.log(e);
        }
    }, [products, totalPrice]);

    const handleAddNewAddress = () => {
        if (currentAddress.trim()) {
            const updatedAddresses = [...additionalAddresses, currentAddress];
            setAdditionalAddresses(updatedAddresses);
            localStorage.setItem(
                "additionalAddresses",
                JSON.stringify(updatedAddresses)
            );
            setCurrentAddress('');
            message.success("Yangi manzil qo'shildi");
        } else {
            message.warning("Iltimos, manzilni kiriting!");
        }
    };

    const showEditModal = (address, index) => {
        setEditAddressIndex(index);
        form.setFieldsValue({ address });
        setIsEditModalVisible(true);
    };

    const handleEditAddress = () => {
        form.validateFields()
            .then((values) => {
                const updatedAddresses = [...additionalAddresses];
                updatedAddresses[editAddressIndex] = values.address;
                setAdditionalAddresses(updatedAddresses);
                localStorage.setItem(
                    "additionalAddresses",
                    JSON.stringify(updatedAddresses)
                );
                setIsEditModalVisible(false);
                message.success("Manzil muvaffaqiyatli yangilandi");
            })
            .catch((info) => {
                console.log("Validatsiya xatosi:", info);
            });
    };

    const handleDeleteAddress = (index) => {
        const updatedAddresses = additionalAddresses.filter(
            (_, i) => i !== index
        );
        setAdditionalAddresses(updatedAddresses);
        localStorage.setItem(
            "additionalAddresses",
            JSON.stringify(updatedAddresses)
        );
        if (selectedAddressIndex === index) {
            setCurrentAddress(userAddress);
            setSelectedAddressIndex(null);
        }
        message.success("Manzil o'chirildi");
    };

    const handleSelectAddress = (address, index) => {
        setCurrentAddress(address);
        setSelectedAddressIndex(index);
    };

    const handleResetToDefaultAddress = () => {
        setCurrentAddress(userAddress);
        setSelectedAddressIndex(null);
    };

    const handlePaymentChange = (e) => {
        setPaymentType(e.target.value);
    };

    const handleKnockChange = (e) => {
        setKnockChoice(e.target.value);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    return <>
        <div className={'w-full h-full flex flex-col items-center'}>
            <div className={'w-full h-24 flex flex-col items-start mt-3 pl-4'}>
                <div className={' max-[500px]:text-sm text-2xl font-bold '}>
                    {currentAddress || userAddress}
                </div>
            </div>

            <div className={'w-full h-auto overflow-y-auto flex flex-col gap-3'}>

                <Collapse accordion bordered={false} defaultActiveKey={['0']}>
                    <Panel header="Savatcha" key="0" className="custom-collapse-panel">
                        <div className={style.container}>
                            <div className={'text-2xl font-bold my-3 '}>Savatcha</div>

                            {backet.map((value, index) => (
                                <SelectedProduct
                                    onClick={() => {
                                        dispatch(setDrawerOpen(true));
                                        dispatch(setSelectedProducts(value));
                                        dispatch(setItemType("saleProduct"));
                                    }}
                                    key={index}
                                    productQuantity={value.count}
                                    cover={value.cover}
                                    percentPromo={22}
                                    capacity={value.price}
                                    title={value.name}
                                    oldPrice={value.price}
                                    price={value.price}
                                    badgeText={'qweqw'}
                                    id={value.id}
                                />
                            ))}
                        </div>
                    </Panel>

                    <Panel header="Qo'shimcha manzillar" key="1" className="custom-collapse-panel">
                        <Input
                            value={currentAddress}
                            onChange={(e) => setCurrentAddress(e.target.value)}
                            placeholder="Yangi manzilni kiriting"
                            className="rounded-lg mb-4"
                        />
                        <Button type="primary bg-green-400" onClick={handleAddNewAddress}>
                            Yangi manzil qo'shish
                        </Button>

                        {additionalAddresses.length > 0 && (
                            <div className="mt-4">
                                <div className="font-semibold">Qo'shimcha manzillar:</div>
                                {additionalAddresses.map((addr, index) => (
                                    <div key={index} className={`flex items-center justify-between mt-2 p-2 rounded-lg ${index === selectedAddressIndex ? 'bg-red-100' : ''}`}>
                                        <span className="cursor-pointer text-green-600" onClick={() => handleSelectAddress(addr, index)}>
                                            {addr}
                                        </span>
                                        <div className="flex items-center">
                                            <Button
                                                type="link"
                                                icon={<HiOutlinePencil className="bg-green-600" />}
                                                onClick={() => showEditModal(addr, index)}
                                            />
                                            <Popconfirm
                                                title="Bu manzilni o'chirishga ishonchingiz komilmi?"
                                                onConfirm={() => handleDeleteAddress(index)}
                                                okText="Ha"
                                                cancelText="Yo'q"
                                            >
                                                <Button
                                                    type="link"
                                                    danger
                                                    icon={<HiOutlineTrash className="text-green-500" />}
                                                />
                                            </Popconfirm>
                                        </div>
                                    </div>
                                ))}
                                <Button type="default bg-blue-500 text-white mt-4" onClick={handleResetToDefaultAddress}>
                                    Asosiy manzilga qaytish
                                </Button>
                            </div>
                        )}
                    </Panel>

                    <Panel header="Eshikni taqillatish" key="2" className="custom-collapse-panel">
                        <div className={'w-full mb-2 text-red-600 font-semibold'}>Eshikni taqillatish</div>
                        <Radio.Group onChange={handleKnockChange} value={knockChoice}>
                            <Radio.Button value="Ha" className="custom-radio-button">
                                Ha
                            </Radio.Button>
                            <Radio.Button value="Yo'q" className="custom-radio-button">
                                Yo`q
                            </Radio.Button>
                        </Radio.Group>
                    </Panel>

                    <Panel header="To'lov turi" key="3" className="custom-collapse-panel">
                        <div className={'w-full mb-2 text-red-600 font-semibold'}>To'lov turi</div>
                        <Radio.Group onChange={handlePaymentChange} value={paymentType}>
                            <Radio.Button value="Naqd pul" className="custom-radio-button">
                                <div className={'flex flex-row items-center'}>
                                    <HiCash className="mr-2 text-red-600" /> Naqd pul
                                </div>
                            </Radio.Button>
                            <Radio.Button value="Karta" className="custom-radio-button">
                                <div className={'flex flex-row items-center'}>
                                    <HiCreditCard className="mr-2 text-red-600" /> Karta
                                </div>
                            </Radio.Button>
                        </Radio.Group>
                    </Panel>

                    <Panel header="Qulay vaqt" key="4" className="custom-collapse-panel">
                        <div className={'w-full mb-2 mt-2 text-red-600 font-semibold'}>Qulay vaqt</div>
                        <TimePicker
                            use12Hours
                            format="HH:mm"
                            onChange={handleTimeChange}
                            value={selectedTime}
                            placeholder="Vaqtni tanlang"
                            className="w-full"
                        />
                    </Panel>
                </Collapse>

                <div className={'w-full h-36 flex flex-col absolute bottom-0 left-0 px-3'}>
                    <div className={'w-full flex items-center justify-between'}>
                        <div className={'font-bold text-black/70'}>Jami</div>
                        <div className={'font-bold text-black/70'}>{totalPrice} so'm</div>
                    </div>

                    <div className={'cursor-pointer w-full h-16 flex items-center justify-center rounded-full bg-red-600 mt-3 text-center text-white'}>
                        <div onClick={confirmOrder} className={'text-2xl font-bold'}>Davom etish</div>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            title="Manzilni tahrirlash"
            visible={isEditModalVisible}
            onOk={handleEditAddress}
            onCancel={() => setIsEditModalVisible(false)}
            okText="Saqlash"
            cancelText="Bekor qilish"
            className="custom-modal"
        >
            <Form form={form} layout="vertical" name="edit_address_form">
                <Form.Item
                    name="address"
                    label="Manzil"
                    rules={[
                        {
                            required: true,
                            message: "Iltimos, manzilni kiriting",
                        },
                    ]}
                >
                    <Input className="rounded-lg" />
                </Form.Item>
            </Form>
        </Modal>
    </>;
}
