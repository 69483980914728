export function extractData(input) {
    input = input?.replace(/\n/g, '').trim();

    const data = {
        title: [],
        badge: [],
        description: "",
        best_b_date: "",
        made_in: "",
        discount: "",
        minus: ""
    };

    // Регулярные выражения для каждого тега
    const regexPatterns = [
        { key: 'title', regex: /%t\s*([^%]*)\s*\//g },
        { key: 'badge', regex: /%b\s*([^%]*)\s*\//g },
        { key: 'best_b_date', regex: /%x\s*([^%]*)\s*\//g },
        { key: 'made_in', regex: /%m\s*([^%]*)\s*\//g },
        { key: 'discount', regex: /%\*disc\s*([^%]*)\s*\//g },
        { key: 'minus', regex: /%&min\s*([^%]*)\s*\//g }
    ];

    // Сначала выделяем и удаляем описание %d, чтобы оно не мешало другим тегам
    const descriptionMatch = input?.match(/%d\s*([^%]*)\s*\//);
    if (descriptionMatch) {
        data.description = descriptionMatch[1].trim();
        input = input.replace(descriptionMatch[0], '').trim();  // Удаляем найденный тег из строки
    }

    // Обрабатываем остальные теги
    regexPatterns.forEach(({ key, regex }) => {
        let match;
        while ((match = regex.exec(input)) !== null) {
            if (key === 'title' || key === 'badge') {
                data[key].push(match[1].trim());
            } else {
                data[key] = match[1].trim();
            }
            // Удаляем найденный тег из строки
            input = input.replace(match[0], '').trim();
        }
    });

    return data;
}




export function checkJSON(jsonString) {
    try {
        // Пытаемся распарсить строку JSON в объект
        const jsonObject = JSON.parse(jsonString);
        // Если удалось, значит строка JSON валидна
        return true;
    } catch (error) {
        // Если возникла ошибка при парсинге, значит строка JSON невалидна
        return false;
    }
}

export function filterProductsBySale(products) {
    // Используем метод filter() для фильтрации продуктов
    const filteredProducts = products.filter(product => product.isSale === true);

    // Используем метод map() для обработки каждого продукта и возврата полных данных
    const fullProducts = filteredProducts.map(product => {
        return {
            ...product,
            language: product.language,
            brands: product.brands,
            categories: product.categories
        };

    });

    return fullProducts;
}

export function findSaleCategory(categories) {
    // Check if categories is an array before using find method
    if (!Array.isArray(categories)) {
        return null; // or handle the error appropriately
    }

    return categories.find(category => category.type === 'sale');
}

export function replaceUrlsAndCategories(text) {
    let description = text;
    let categories = [];

    // Replace URLs
    description = description.replace(/\[url href="(.*?)"\](.*?)\[\/url\]/g, '<a href="$1" target="_blank" style="text-decoration: underline; color: #002957">$2</a>');

    // Replace categories
    const categoriesRegex = /\[categories\]([\s\S]*?)\[\/categories\]/g;
    const match = categoriesRegex.exec(text);
    if (match && match.length > 1) {
        categories = match[1].split(',').map(cat => cat.trim());
        description = description.replace(categoriesRegex, '');
    }

    return { description, categories };
}


export function removeSliderCategory(data) {
    // Копируем исходный массив данных
    const newData = JSON.parse(JSON.stringify(data));

    // Фильтруем массив и оставляем только те объекты, у которых тип не "slider"
    return newData.filter(item => item.type !== "slider");
}
export function findSliderCategory(data) {
    // Копируем исходный массив данных
    const newData = JSON.parse(JSON.stringify(data));

    // Фильтруем массив и оставляем только те объекты, у которых тип "slider"
    return [newData.find(item => item.type === "slider")];
}
export function transformCoverArray(coverArray) {
    if (Array.isArray(coverArray)) {
        return coverArray.map(url => ({url: `https://api.osonexpress.uz/${url}`}));
    } else {
        return []
    }
}

export function filterCategories(categoriesFromServer, selectedCategoryNames) {
    const filteredCategories = categoriesFromServer.map(category => {
        const filteredCategory = {
            ...category,
            categories: category.categories.filter(cat => selectedCategoryNames.includes(cat.name.toLowerCase()))
        };
        return filteredCategory;
    });

    return filteredCategories;
}
export function getRandomElementsFromArray(arr, count) {
    const filtered = arr.filter(item => item.categories.name !== 'slider'); // Фильтруем элементы, исключая те, у которых категория 'slider'
    const shuffled = filtered.sort(() => 0.5 - Math.random()); // Случайным образом перемешиваем отфильтрованный массив
    return shuffled.slice(0, Math.min(count, shuffled.length)); // Выбираем первые count элементов, но не больше, чем есть в массиве
}

export const filterProducts = (products, json) => {
    // Проверяем наличие списка продуктов и его тип
    if (!Array.isArray(products)) {
        console.error('Products is not an array');
        return [];
    }

    const productIdsWithCounts = json.products.reduce((acc, item) => {
        acc[item.product_id] = item.count;
        return acc;
    }, {});

    return products
        .filter(product => productIdsWithCounts.hasOwnProperty(product.id) && productIdsWithCounts[product.id] > 0)
        .map(product => ({
            ...product,
            count: productIdsWithCounts[product.id]
        }));
};
