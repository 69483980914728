import React, { useState, useEffect } from "react";
import { FaMinus } from "react-icons/fa";
import { CgMathPlus } from "react-icons/cg";
import { message } from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectDrawerOpen} from "../store/selectors/drawerSelector";
import {addToTotalPrice, subtractFromTotalPrice} from "../store/actions/priceAction";

export default function ProductCard({
                                        cover,
                                        percentPromo,
                                        title,
                                        oldPrice,
                                        price,
                                        capacity,
                                        badgeText,
                                        onClick,
                                        id,
                                        height,
                                        width,
                                        initialState // Добавляем новый пропс для передачи начального состояния
                                    }) {
    const [isActive, setActive] = useState(initialState?.isActive); // Используем начальное состояние из пропса
    const [quantity, setQuantity] = useState(initialState?.quantity); // Используем начальное состояние из пропса
    const [myCount, setMyCount] = useState(0); // Состояние для отслеживания количества товара в корзине
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const totalPrice = useSelector(state => state.price.totalPrice);

    // Получаем функцию dispatch из Redux
    const dispatch = useDispatch();

    // Функции для обработки событий клика на кнопках
    const handleAddToTotalPrice = () => {
        dispatch(addToTotalPrice( parseInt(price))); // Добавляем 50 к общей цене
    };

    const handleSubtractFromTotalPrice = () => {
        dispatch(subtractFromTotalPrice(parseInt(price))); // Вычитаем 50 из общей цены
    };
    useEffect(()=>{},[totalPrice])

    useEffect(() => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };
        const myCount = getMycount(id, parsedData);
        setMyCount(myCount);
    }, [id,isDrawerOpen]); // Обновляем myCount при изменении id

    const updateBasket = (productId, type) => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };

        // Проверяем, есть ли элемент с данным product_id
        const existingProductIndex = parsedData.products.findIndex(product => product.product_id === productId);

        if (existingProductIndex !== -1) {
            if (type === 'increment') {
                parsedData.products[existingProductIndex].count++;
            } else if (type === 'decrement') {
                if ( parsedData.products[existingProductIndex].count >0){
                    parsedData.products[existingProductIndex].count--;
                } else {
                    message.info('продукт не может быть ниже одного количество')
                }

            }
        } else {
            // Элемент не найден - добавляем новый элемент
            parsedData.products.push({ product_id: productId, count: 1 });
        }

        // Сохраняем обновленные данные в localStorage
        localStorage.setItem("basket", JSON.stringify(parsedData));
        setMyCount(getMycount(id, parsedData));
    };

    const getMycount = (productId, data) => {
        const existingProductIndex = data?.products?.findIndex(product => product?.product_id === productId);
        if (existingProductIndex !== -1) {
            return data?.products[existingProductIndex]?.count;
        } else {
            return 0;
        }
    };

    const handleIncrement = () => {
        updateBasket(id, 'increment');
        handleAddToTotalPrice()
    };

    const handleDecrement = () => {
        updateBasket(id, 'decrement');
        handleSubtractFromTotalPrice()
    };

    return (
        <>
            <div
                className={` ${
                    width ? width : `w-40`
                }  h-auto flex flex-col items-start  py-1 cursor-pointer `}
            >
                <div
                    onClick={onClick}
                    className={`w-full ${
                        height ? height : `h-44`
                    }  bg-[#f2f2f2] rounded-2xl flex flex-col justify-end relative overflow-hidden`}
                >
                    <img
                        className={"w-full h-full object-contain absolute top-0 z-0"}
                        src={`https://api.osonexpress.uz/${cover[0]}`}
                        alt={title}
                    />
                    <div className={"w-full h-full flex flex-col items-start justify-end p-3 z-10"}>
                        {percentPromo?.length > 1 ? (
                            <span className={"text-[11px] text-white font-bold py-0.5 px-1 shadow-xl rounded-2xl bg-black"}>
                -{percentPromo}%
              </span>
                        ) : (
                            ``
                        )}
                        {isActive ? (
                            <div className={"absolute top-0 w-full h-full bg-black/30 left-0 flex flex-col items-center justify-center "}>
                                <div className={"text-3xl font-bold text-white"}>{myCount}</div>
                            </div>
                        ) : (
                            ``
                        )}
                    </div>
                </div>
                <div className={"w-full h-auto flex flex-col items-start pl-1"}>
                    <div className={"text-base line-clamp-3 font-semibold "}>{title?.length > 15?title?.substring(0,15)+'...':title}</div>
                    <div className={"w-full flex justify-between "}>
                        <div className={"text-[14px] line-clamp-1 font-bold text-black/50"}>{capacity}</div>
                        <img width={24} height={24} src={`https://api.osonexpress.uz/${badgeText?.logo}`} alt={badgeText?.name}/>
                    </div>
                </div>
                <div className={` ${
                    isActive ? "bg-red-600" : "bg-[#f2f2f2]"
                } w-full duration-300 px-2 py-1 mt-2 rounded-xl flex items-center justify-center gap-3 active:translate-y-0.5 duration-300`}>
                    {isActive ? (
                        <div className={"w-full justify-between flex items-center gap-3 text-white select-none duration-300"}>
                            <FaMinus onClick={handleDecrement} className={"text-xl"}/>
                            <span className={"text-[12px] font-bold flex items-center gap-1 line-clamp-1"}>{price} so'm</span>
                            <CgMathPlus onClick={handleIncrement} className={"text-xl"}/>
                        </div>
                    ) : (
                        <div onClick={() => {
                            setActive(true);
                            setQuantity(1);
                        }} className={"w-full flex items-center justify-center gap-3 select-none text-red-500 "}>
                            {oldPrice ? (
                                <span className={"line-through text-black/50 font-bold text-[10px] line-clamp-1 "}>{oldPrice} so'm</span>
                            ) : (
                                ``
                            )}
                            <span className={"text-[12px] font-bold flex items-center gap-1 line-clamp-1"}>
                {price} so'm<CgMathPlus className={"text-xl"}/>
              </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
