import {ADD_PRODUCT} from "../actions/ProductActions";


const initialState = {
    products: {}
};

const productReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                products: action.payload
            };
        default:
            return state;
    }
};

export default productReducer;
