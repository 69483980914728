import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {IoIosArrowRoundBack} from "react-icons/io";
import {ON_SALE_PRODUCTS} from "../../CustomData/CustomData";
import ProductCard from "../ProductCard/ProductCard";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {setSelectedProducts} from "../store/actions/selectedProductActions";
import {setItemType} from "../store/actions/selectedItemTypeAction";
import More_Card from "../More_Card/More_Card";
import Custom_Swiper from "../Custom_Swiper/Custom_Swiper";
import {useDispatch} from "react-redux";
import {fetchCategoriesById, fetchCategoriesTypeByID} from "../../Api/Categories/Categories";
import {filterProductsBySale, findSliderCategory, replaceUrlsAndCategories} from "../../utils";
import {fetchProducts} from "../../Api/Products/products";


export default function Category_Products_Layout() {

    const dispatch = useDispatch()
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryWithProduct, setCategoryWithProduct] = useState([]);
    const [selected, setSelectedId] = useState(0);
    const [currentCategories, setCurrentCategory] = useState([]);
    const pom = useParams();

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };
    console.log(pom)


    useEffect(() => {
        if (pom.isSale) {
            fetchProductsApi()
        } else if (pom.doSale) {
            fetchWithProduct()
            fetchCategoryBy()
        } else {
            fetchCategoryBy()
            fetchCurrentCategoryTpe()
        }

    }, [pom])
    const fetchWithProduct = async () => {
        const data = fetchCategoriesTypeByID(pom.id)
        data.then(res=>{
            setCategoryWithProduct(res)
            setCurrentCategory(res.categories)
            console.log(res)
        })
    }
    const fetchProductsApi = async () => {
        const data = fetchProducts('uz')
        data.then(res => {
            setProducts(filterProductsBySale(res))
        })

    }

    const fetchCategoryBy = async () => {
        const data = fetchCategoriesById('uz', pom.id);
        data.then(res => {
            if (pom.isSale) {
                setProducts(filterProductsBySale(res.data.products))
            } else {
                setProducts(res.data.products)
            }
            // setProducts(res.data.products)
            setCategory(res.data)
        })

    }

    const fetchCurrentCategoryTpe = async () => {
        const data = fetchCategoriesTypeByID(pom.type)
        data.then(res => {
            setCurrentCategory(res.categories)
        })
    }

    return <>
        <div
            className={'w-full h-auto min-h-screen bg-white max-[1000px]:px-0 px-4 block box-border z-20 rounded-b-3xl pb-12'}>

            <div className={'w-full flex items-center gap-4 '}>

                <Link to={'/'} className={'py-2 px-4 text-4xl rounded-3xl bg-[#f2f2f2]'}>

                    <IoIosArrowRoundBack/>

                </Link>

                <span className={'text-black text-3xl font-bold'}>{pom.big}</span>

            </div>

            <div className={'w-full flex items-center flex-wrap gap-2 justify-start mt-4'}>

                {currentCategories.map((value, index) => (
                    <Link
                        to={ pom.doSale ?`/products/${value.id}/${value.name}/${categoryWithProduct.name}/${categoryWithProduct.id}` :`/products/${value.id}/${value.name}/${pom.big}/${pom.type}`}
                        onClick={() => setSelectedId(value.id)} // Используем setSelectedId для установки выбранного id
                        className={`px-3 py-1 rounded-full ${selected === value.id ? 'bg-green-500 text-white ' : 'bg-[#f2f2f2]'} text-sm font-bold text-black/60`}
                        key={index} // Добавляем key для элементов списка

                    >
                        {value?.name}
                    </Link>
                ))}

            </div>

            {/*Products on sale */}
            {!pom.isSale && !pom.doSale ?
                <div className={'w-full h-auto flex flex-col items-start gap-3 '}>

                    <span className={'text-2xl font-bold text-black/60'}>{!pom.isSale?'Foydali savatcha':''}</span>

                    <div className={'w-full h-72 flex flex-row items-start gap-3 max-[1190px]:hidden '}>

                        {filterProductsBySale(products).slice(0, 4).map((value, index) => <ProductCard key={index}
                                                                                                       cover={value.cover}
                                                                                                       percentPromo={0}
                                                                                                       capacity={value.brief_description}
                                                                                                       title={value.name}
                                                                                                       oldPrice={'1000'}
                                                                                                       price={value.price}
                                                                                                       id={value.id}
                                                                                                       badgeText={value.brands}
                                                                                                       onClick={() => {
                                                                                                           dispatch(setDrawerOpen(true));
                                                                                                           dispatch(setSelectedProducts(value));
                                                                                                           dispatch(setItemType("saleProduct"));
                                                                                                       }}
                                                                                                       initialState={getProductCount(value.id)}/>)}

                        <More_Card height={'h-44'} array={filterProductsBySale(products)}
                                   link={`/products/${pom.id}/Sale/${pom.big}/${pom.type}/1`}/>

                    </div>

                    <div
                        className={'w-full h-72 flex flex-row items-start gap-3 max-[1000px]:hidden min-[1190px]:hidden '}>

                        {filterProductsBySale(products).slice(0, 4).map((value, index) => <ProductCard key={index}
                                                                                                       cover={value.cover}
                                                                                                       percentPromo={0}
                                                                                                       capacity={value.brief_description}
                                                                                                       title={value.name}
                                                                                                       oldPrice={'1000'}
                                                                                                       price={value.price}
                                                                                                       id={value.id}
                                                                                                       badgeText={value.brands}
                                                                                                       onClick={() => {
                                                                                                           dispatch(setDrawerOpen(true));
                                                                                                           dispatch(setSelectedProducts(value));
                                                                                                           dispatch(setItemType("saleProduct"));
                                                                                                       }}
                                                                                                       initialState={getProductCount(value.id)}/>)}

                    </div>

                    <div className={'w-full h-72 flex flex-row items-start gap-3  min-[1000px]:hidden '}>

                        <Custom_Swiper array={filterProductsBySale(products).slice(0, 4)}/>

                    </div>

                </div> : ''}



            {pom.doSale?<>
            {categoryWithProduct?.categories?.map(value =><>
                <div className={'text-2xl font-bold text-black/60 flex flex-row'}><img width={24} height={24} className={'object-contain ml-2 mr-2'} src={`https://api.osonexpress.uz/${value.icon.icon}`}/> {value?.name}</div>

                <div
                    className={'w-full h-auto flex flex-wrap max-[1000px]:justify-center justify-start items-start gap-3 mt-6 '}>

                    {value.products.map((value, index) => (
                        <ProductCard
                            key={index}
                            cover={value.cover}
                            percentPromo={0}
                            capacity={value.brief_description}
                            title={value.name}
                            oldPrice={'1000'}
                            price={value.price}
                            id={value.id}
                            badgeText={value.brands}
                            onClick={() => {
                                dispatch(setDrawerOpen(true));
                                dispatch(setSelectedProducts(value));
                                dispatch(setItemType("saleProduct"));
                            }}
                            initialState={getProductCount(value.id)} // Передаем начальное состояние для каждого компонента
                        />
                    ))}

                </div>
            </>)}
            </> :<> <div className={'text-2xl font-bold text-black/60'}>{category.name}</div>

                <div
                className={'w-full h-auto flex flex-row flex-wrap max-[1000px]:justify-center justify-start items-start gap-3 mt-6 '}>

            {products.map((value, index) => (
                <ProductCard
                key={index}
                cover={value.cover}
                percentPromo={0}
                capacity={value.brief_description}
                title={value.name}
                oldPrice={'1000'}
                price={value.price}
                id={value.id}
                badgeText={value.brands}
                onClick={() => {
                dispatch(setDrawerOpen(true));
                dispatch(setSelectedProducts(value));
                dispatch(setItemType("saleProduct"));
            }}
                initialState={getProductCount(value.id)} // Передаем начальное состояние для каждого компонента
                />
                ))}

                </div> </>}


        </div>
    </>
}
