import React, {useEffect, useState} from "react";
import {IoIosCheckmarkCircle} from "react-icons/io";
import {FaArrowLeftLong, FaRegUser} from "react-icons/fa6";
import telegram_icon from '../../../assets/img/icons/telegram_icon.png'
import {Form, Input, Button, Switch, message, Typography} from 'antd';
import Item from "antd/lib/list/Item";
import loginApi, {registerApi} from "../../../../Api/Auth/AuthApi";
import {useDispatch} from "react-redux";
import {setDrawerOpen} from "../../../store/actions/drawerAction";
import {loginUser} from "../../../store/actions/userAction";
import { FaArrowLeft } from "react-icons/fa";
import {useTelegram} from "../../../../hooks/TelegramHooh";
import Profile from "../Profile/Profile";
import {GiRamProfile} from "react-icons/gi";
import {BsBack} from "react-icons/bs";
import {BiArrowBack} from "react-icons/bi";


export default function LoginContent() {

    const [mobileActive, setMobileActive] = useState(false)
    const [isRegister, setIsRegister] = useState(false);
    const [form] = Form.useForm();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const profile = localStorage.getItem('profile');
    const dispatch = useDispatch();
    const {user} = useTelegram()
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
       const user =   await loginApi({
                phoneNumber,
                password
            })
            message.success('Вы успешго вошли в аккаунт ' + user?.user?.fullname)
            localStorage.setItem('login',true);
            localStorage.setItem('address',user?.user.address)
            dispatch(setDrawerOpen(false))
            dispatch(loginUser(user?.user))

            // Дополнительные действия после успешного входа...
        } catch (error) {
            console.error('Error while logging in:', error);
            // Обработка ошибки при входе...
        }
    };

    const getRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < 8; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
    };
    useEffect(()=>{},[])
    const onFinish = async (values) => {
        if (user?.id.toString().length> 0 ){

        } else {
            try {
                const randomString = getRandomString();
                values.telegram_id = `unknown${randomString}`;
                values.chat_id = `unknown${randomString}`;
                const response = await registerApi(values);
                message.success('Muvoffaqiyatli o`tdingiz ro`yxatdan')
                form.resetFields()
            } catch (e){
                message.error('Xatolik yuz berdi')
            }

        }
    }
    return <>
        <div className={'w-full h-full flex flex-col items-center justify-end '}>
            {profile !== null ? <Profile/> :

                    mobileActive ?
                        <div className={'w-full h-full flex flex-col items-center justify-center relative'}>

                            <div onClick={() => setMobileActive(false)}
                                 className={'px-3 py-2 bg-[#f2f2f2] rounded-xl flex flex-col items-center justify-center text-2xl absolute left-0 top-0 '}>

                                <FaArrowLeftLong/>

                            </div>

                            <div className={'w-full h-auto flex flex-col items-center gap-4 mb-56'}>
                                {
                                    isRegister ?
                                        <Form className={'bg-blue-500 w-full p-5 rounded-lg'} form={form}
                                              layout="vertical" onFinish={onFinish}>

                                            <Form.Item
                                                label={<label style={{color: "white"}}>Полное имя</label>}
                                                name="fullname"
                                                style={{color: "white"}}
                                                rules={[
                                                    {required: true, message: 'Пожалуйста, введите полное имя!'},
                                                    // Дополнительные правила валидации можно добавить здесь
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item
                                                label={<label style={{color: "white"}}>Номер телефона</label>}
                                                name="phoneNumber"
                                                rules={[
                                                    {required: true, message: 'Пожалуйста, введите номер телефона!'},
                                                    // Дополнительные правила валидации можно добавить здесь
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item
                                                label={<label style={{color: "white"}}>Пароль</label>}
                                                name="password"
                                                rules={[
                                                    {required: true, message: 'Пожалуйста, введите пароль!'},
                                                    {
                                                        min: 6,
                                                        message: 'Пароль должен содержать как минимум 6 символов!'
                                                    },
                                                    // Дополнительные правила валидации можно добавить здесь
                                                ]}
                                            >
                                                <Input.Password/>
                                            </Form.Item>
                                            <Form.Item label={<label style={{color: "white"}}>Адресс</label>} rules={[
                                                {required: true, message: 'Пожалуйста, введите адресс!'},
                                                // Дополнительные правила валидации можно добавить здесь
                                            ]} name="address">
                                                <Input/>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button className={'bg-green-600 text-white'} htmlType="submit">
                                                    Отправить
                                                </Button>
                                                <div
                                                    onClick={()=>{
                                                        setIsRegister(false)
                                                    }
                                                    }  className={'flex  bg-red-500 p-2 rounded items-center cursor-pointer text-white flex-row mt-2 w-full self-end mb-2 mr-2 gap-3 '}> {isRegister ? 'вернутся в панел входа' : ' Вы еще не прошли регистрацию ? '}
                                                    <BiArrowBack/>
                                                    <Typography className={'text-white'}>  Логин </Typography>
                                                </div>
                                            </Form.Item>
                                        </Form> :
                                        <div className={'w-full h-auto flex flex-col items-center gap-4 mb-56'}>
                                            <form onSubmit={handleSubmit} className={'w-full'}>
                                                <div className={'text-balance text-black/50 font-bold mb-2'}>Телефон
                                                </div>
                                                <input
                                                    className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300  border-black/20'}
                                                    type="text"
                                                    placeholder={`+998`}
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e?.target?.value)}
                                                />
                                                <div
                                                    className={'text-balance text-black/50 font-bold mb-2 mt-4'}>Пароль
                                                </div>
                                                <input
                                                    className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300  border-black/20'}

                                                    type={'password'}
                                                    placeholder={`Пароль`}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <div
                                                  onClick={()=>{
                                                  setIsRegister(true)
                                                  }
                                                  }  className={'flex  bg-blue-500 p-2 rounded items-center cursor-pointer text-white flex-row mt-2 w-full self-end mb-2 mr-2 justify-between'}> {isRegister ? 'вернутся в панел входа' : ' Вы еще не прошли регистрацию ? '}
                                                    <FaRegUser/>
                                                    <Typography className={'text-white'}>  Регистрация</Typography>
                                                </div>

                                                <button
                                                    type="submit"
                                                    className={'w-full h-16 flex items-center justify-center rounded-full bg-red-600 mt-6 text-2xl font-bold text-white outline-none cursor-pointer select-none'}
                                                >
                                                    Войти
                                                </button>

                                            </form>

                                        </div>
                                }

                            </div>

                            <div className={'w-full flex flex-col items-start gap-3 absolute bottom-3'}>

                                <p className={' text-sm'}>

                            <span
                                className={'text-black/50 font-bold mx-1'}>Продолжая авторизацию, вы соглашаетесь с</span>
                                    <span className={' font-bold'}>политикой конфиденциальности</span> ,
                                    <span className={' font-bold'}>условиями сервиса</span> и
                                    <span className={' font-bold'}>условиями продажи товаров</span>

                                </p>

                            </div>
                        </div>
                        :
                        <div className={'w-full h-auto flex flex-col items-center'}>
                            <div className={'w-72 h-auto flex flex-col gap-4 mb-56'}>

                                <div
                                    className={'cursor-pointer w-full h-14 rounded-full bg-[#f2f2f2] flex items-center justify-center '}>
                                    <div onClick={() => setMobileActive(true)}
                                         className={'text-lg font-semibold text-black/80'}>
                                        Войти по номеру телефона
                                    </div>
                                </div>
                                {/*<div className={'cursor-pointer w-full h-14 rounded-full bg-[#21a038] flex items-center justify-center '}>*/}
                                {/*    <div className={'text-lg font-semibold text-white'}>*/}
                                {/*        Войти по Сбер ID*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div
                                    className={'cursor-pointer w-full h-20 rounded-lg bg-white flex  items-center justify-center shadow-xl '}>
                                    <img className={'w-[15%] h-full object-contain mr-2'} src={telegram_icon}/>
                                    <div className={'flex flex-col items-start  '}>
                                        <div className={'text-lg font-semibold'}>OsosnExpress Telegram</div>
                                        <div className={'text-xs font-semibold text-black/80'}>Вы можете пользоваться
                                            услугами
                                            OsonExpress с помощью телеграм бота
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className={'w-full flex flex-col items-start gap-3'}>

                                <div className={'flex items-center text-sm'}>

                                    <IoIosCheckmarkCircle className={'text-3xl text-red-500'}/>
                                    <div className={'text-black/50 font-bold mx-1'}>Получать</div>
                                    <div className={' font-bold'}>предложения об акциях и скидках</div>

                                </div>
                                <div className={'flex items-center text-sm'}>

                                    <IoIosCheckmarkCircle className={'text-3xl text-red-500'}/>
                                    <div className={'text-black/50 font-bold mx-1'}>Делиться</div>
                                    <div className={' font-bold'}>данными с партнёрами OsonExpress</div>

                                </div>

                                <p className={' text-sm'}>

                            <span
                                className={'text-black/50 font-bold mx-1'}>Продолжая авторизацию, вы соглашаетесь с</span>
                                    <span className={' font-bold'}>политикой конфиденциальности</span> ,
                                    <span className={' font-bold'}>условиями сервиса</span> и
                                    <span className={' font-bold'}>условиями продажи товаров</span>

                                </p>

                            </div>
                        </div>

            }

        </div>
    </>
}
