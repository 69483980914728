import React from "react";
import {Link} from "react-router-dom";

export default function Offer_Card({key,title,cover,link}) {

    return<>
        <Link to={link} onClick={()=>window.scrollTo(0,0)} key={key} className={` max-[400px]:w-36 max-[800px]:w-44 w-48 h-60 bg-[#f2f2f2] rounded-2xl flex flex-col items-start relative overflow-hidden`}>

            <img className={' max-[400px]:w-24 max-[800px]:w-36 w-40 h-fit object-contain absolute bottom-6 left-0 right-0 m-auto z-0 '} src={'https://api.osonexpress.uz/'+cover} alt={title}/>

            <div className={'z-10 w-full h-full flex flex-col items-start p-3'}>
                <div className={'text-black text-xl font-semibold line-clamp-3'}>{title}</div>
            </div>

        </Link>
    </>
}
