const initialState = {
    selectedProducts: []
}

export const selectedProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_PRODUCT':
            return {...state, selectedProducts: action.payload}
        case 'REMOVE_SELECTED_PRODUCT':
            return {...state, selectedProducts: state.selectedProducts.filter(product => product.id !== action.payload.id)}
        case 'CLEAR_SELECTED_PRODUCTS':
            return {...state, selectedProducts: []}
        default:
            return state;
    }
}
