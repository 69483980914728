import {SET_PROMO} from "../actions/promoActions";

const initialState = {
    promo: {} // Начальное состояние для промо
};

const promoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROMO:
            return {
                ...state,
                promo: action.payload // Обновляем промо данными из действия
            };
        default:
            return state;
    }
};

export default promoReducer;
