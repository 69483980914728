import React from "react";
import {GoArrowRight} from "react-icons/go";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

export default function More_Card({array,height,onClick,link,isSale}) {
    //height props accepts only tailwind class

    const calculatedHeight = height && height.length > 0 ? height : 'h-60';

    return<>
        <Link to={link} onClick={onClick} className={` ${calculatedHeight} w-48  rounded-2xl grid grid-cols-2 relative overflow-hidden gap-1 `}>

            <div className={'w-full h-full absolute top-0 flex flex-col items-center justify-center bg-[#404040]/70'}>

                <div className={'px-3 py-2 rounded-2xl bg-[#ffffff] text-[14px] font-semibold flex items-center gap-1'}>
                        <span>Ko`proq</span>
                    <GoArrowRight className={'text-[14px] text-black translate-y-0.5'}/>
                </div>

            </div>
            {
                isSale?
                    array?.slice(-4).map((value, index) => <img key={index} className={'w-full h-full object-cover'} src={`https://api.osonexpress.uz/${value?.icon.icon}`} alt={value?.name}/>):
                    array?.slice(-4).map((value, index) => <img key={index} className={'w-full h-full object-cover'} src={`https://api.osonexpress.uz/${value?.cover[0]}`} alt={value?.name}/>)
            }

        </Link>
    </>
}
