import React, {useEffect, useState} from "react";
import style from "./style.module.css";
import {Link} from "react-router-dom";
import {ON_SALE_PRODUCTS} from "../../../../CustomData/CustomData";

import {useDispatch, useSelector} from "react-redux";
import {setDrawerOpen} from "../../../../components/store/actions/drawerAction";
import {setSelectedProducts} from "../../../../components/store/actions/selectedProductActions";
import {setItemType} from "../../../../components/store/actions/selectedItemTypeAction";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import {selectDrawerOpen} from "../../../../components/store/selectors/drawerSelector";
import fetchCategories, {fetchCategoriesById} from "../../../../Api/Categories/Categories";
import {getRandomElementsFromArray} from "../../../../utils";

export default function Mob_SelectedPromoContent() {

    const dispatch = useDispatch()
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const promoData = useSelector(state => state.promo.promo);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : {products: []};
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0 ? true : false,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return {isActive: false, count: 0};
        }
    };
    useEffect(() => {

        fetchCategoriesApi()
    }, [isDrawerOpen, promoData])
    const fetchCategoriesApi = async () => {
        console.log(promoData)
        const data = await fetchCategoriesById('uz', promoData?.id)
        const cat = await fetchCategories('uz')
        console.log(cat)
        setCategories(cat.categories)
        console.log(data.data)
        setProducts(data.data.products)
    }

    return<>
        <div className={style.container}>

            <div className={'w-full flex flex-col items-start gap-3 '}>

                <div className={'w-full h-96 bg-[#e5f0d0] block rounded-lg overflow-hidden relative shadow'}>

                    <img className={'w-full h-full object-contain'} src={`https://api.osonexpress.uz/${products[products?.length-1]?.cover[0]}`} alt={''}/>



                </div>

                <div className={'flex flex-col gap-2 items-start'}>

                    <p className={'text-2xl font-bold'}>{promoData?.name}</p>

                    <p className={'text-balance font-semibold'}>{promoData?.brief_description}</p>

                </div>

                <div className={'w-full h-auto flex flex-col items-start gap-3 overflow-x-auto'}>
                    <div className={'h-auto py-2 flex flex-row px-4 items-start gap-3'}>
                        {/*{Categories_offer.map((value, index)=> (*/}
                        {/*    <Link key={index} to={'#'} className={'px-3 py-1 whitespace-nowrap rounded-full bg-[#f2f2f2] text-sm font-bold text-black/60'}>*/}
                        {/*        {value}*/}
                        {/*    </Link>*/}
                        {/*))}*/}
                    </div>
                </div>

            </div>




            <div className={'w-full h-auto flex flex-wrap max-[1000px]:justify-center justify-start items-start gap-3 mt-6 '}>

                {products.map((product, index) => <ProductCard onClick={()=>{
                    dispatch(setDrawerOpen(true))
                    dispatch(setSelectedProducts(product))
                    dispatch(setItemType("saleProduct"))
                }}
                                                                     key={index}

                                                             cover={product.cover}
                                                             percentPromo={0}
                                                             capacity={product.brief_description}
                                                             title={product.name}
                                                             oldPrice={'1000'}
                                                             price={product.price}
                                                             id={product.id}
                                                             badgeText={product.brands}
                                                             initialState={getProductCount(product.id)}/>)}

            </div>

        </div>
    </>
}
