import React, { useEffect, useState } from "react";
import { FaArrowLeftLong, FaRegUser } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Button, Form, message, Typography } from "antd";
import loginApi, { registerApi } from "../../../../Api/Auth/AuthApi";
import { setDrawerOpen } from "../../../../components/store/actions/drawerAction";
import { loginUser } from "../../../../components/store/actions/userAction";
import { useDispatch } from "react-redux";
import { useTelegram } from "../../../../hooks/TelegramHooh";
import Profile from "../../../../components/CustomDrawer/components/Profile/Profile";
import { BiArrowBack } from "react-icons/bi";
import telegram_icon from "../../../../components/assets/img/icons/telegram_icon.png";
import { initMiniApp } from "@telegram-apps/sdk-react";
import CustomKeyboard from "../../../../utils/CustomKeyboard";

export default function Mob_LoginContent() {
    const [mobileActive, setMobileActive] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [miniApp, setMiniApp] = useState(null);
    const [form] = Form.useForm();
    const profile = localStorage.getItem('profile');
    const dispatch = useDispatch();
    const { user, tg } = useTelegram();
    const [myTgData, setMyTgData] = useState({});
    const [activeInput, setActiveInput] = useState(null);
    const [inputValues, setInputValues] = useState({
        phoneNumber: '',
        password: '',
        fullname: '',
        address: '',
        seco:''
    });

    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    const handleKeyPress = (key) => {
        if (activeInput) {
            setInputValues(prevValues => {
                const newValue = key === 'Backspace'
                    ? prevValues[activeInput].slice(0, -1)
                    : prevValues[activeInput] + (key === 'Space' ? ' ' : key);

                // Откладываем обновление формы до следующего цикла рендеринга
                setTimeout(() => {
                    form.setFieldsValue({ [activeInput]: newValue });
                }, 0);

                return {
                    ...prevValues,
                    [activeInput]: newValue
                };
            });
        }
    };

    const handleFocus = (inputName) => {
        setActiveInput(inputName);
        setIsKeyboardVisible(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsKeyboardVisible(false)
        try {
            const user = await loginApi({
                phoneNumber: inputValues.phoneNumber,
                password: inputValues.password
            });
            message.success('Вы успешно вошли в аккаунт ' + user?.user?.fullname);
            localStorage.setItem('login', true);
            localStorage.setItem('address', user?.user.address);
            dispatch(setDrawerOpen(false));
            dispatch(loginUser(user?.user));
        } catch (error) {
            console.error('Error while logging in:', error);
        }
    };

    const getRandomString = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < 8; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
    };

    const onFinish = async (values) => {
        setIsKeyboardVisible(false)
        if (user?.id.toString().length > 0) {
        } else {
            try {
                const randomString = getRandomString();
                if (myTgData.phoneNumber) {
                    values.phoneNumber = myTgData.phoneNumber;
                }
                values.telegram_id = myTgData.telegram_id ? myTgData.telegram_id : `unknown${randomString}`;
                values.chat_id = myTgData.telegram_id ? myTgData.telegram_id : `unknown${randomString}`;
                const response = await registerApi(values);
                message.success('Вы успешно прошли регистрацию');
                form.resetFields();
            } catch (e) {
                message.error('Произошла ошибка');
            }
        }
    };

    const handleTelegramLogin = async () => {
        if (miniApp) {
            try {
                const contact = await miniApp.requestContact();
                const data = contact?.contact;
                const phoneNumber = data?.phoneNumber;
                const fullname = `${data?.firstName} ${data?.lastName}`;
                const telegram_id = data?.userId;

                // Обновляем состояния
                setInputValues(prevValues => ({
                    ...prevValues,
                    phoneNumber,
                    fullname
                }));
                setMyTgData({ phoneNumber, fullname, telegram_id });

                // Откладываем обновление формы до следующего цикла рендеринга
                setTimeout(() => {
                    form.setFieldsValue({ phoneNumber, fullname });
                }, 0);

            } catch (error) {
                console.error("Error requesting contact:", error);
                message.error('Failed to request contact');
            }
        } else {
            message.warning('Mini app is not available');
        }
    };

    useEffect(() => {
        if (user !== undefined && !miniApp) {
            const initializeMiniApp = async () => {
                try {
                    const [miniApp] = await initMiniApp();
                    setMiniApp(miniApp);
                } catch (error) {
                    console.error("Error initializing mini app:", error);
                }
            };
            initializeMiniApp();
        }
    }, [user, miniApp]);

    return (
        <div  className={'w-full h-full flex flex-col items-center justify-end'}>
            {profile !== null ? <Profile /> :
                mobileActive ?
                    <div className={'w-full h-full flex flex-col items-center justify-center relative'}>
                        <div onClick={() => setMobileActive(false)}
                             className={'px-3 py-2 bg-[#f2f2f2] rounded-xl flex flex-col items-center justify-center text-2xl absolute left-0 top-0 mt-2 ml-2 '}>
                            <FaArrowLeftLong />
                        </div>

                        <div className={'w-full h-auto flex flex-col items-center gap-4 mb-56 mt-6 p-4'}>
                            {
                                isRegister ?
                                    <Form className={'bg-blue-500 w-full p-5 rounded-lg'} form={form}
                                          layout="vertical" onFinish={onFinish}>
                                        <Form.Item label={<label style={{ color: "white" }}>Полное имя</label>} rules={[
                                            { required: true, message: 'Пожалуйста, введите имя!' },
                                        ]} name="fullname">
                                            <input
                                                className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                value={inputValues.fullname}
                                                onFocus={() => handleFocus('fullname')}
                                                readOnly
                                            />
                                        </Form.Item>

                                        {miniApp ? <button
                                                onClick={handleTelegramLogin}
                                                className='w-full bg-gray-800 text-white p-3 rounded-md hover:bg-gray-700'
                                            >
                                                Request Contact from Telegram
                                            </button> :
                                            <Form.Item
                                                label={<label style={{ color: "white" }}>Номер телефона</label>}
                                                name="phoneNumber"
                                                rules={[
                                                    { required: true, message: 'Пожалуйста, введите номер телефона!' },
                                                ]}
                                            >
                                                <input
                                                    className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                    value={inputValues.phoneNumber}
                                                    onFocus={() => handleFocus('phoneNumber')}
                                                    readOnly
                                                />
                                            </Form.Item>

                                        }
                                        <Form.Item
                                            label={<label style={{ color: "white" }}>Пароль</label>}
                                            name="password"
                                            rules={[
                                                { required: true, message: 'Пожалуйста, введите пароль!' },
                                                {
                                                    min: 6,
                                                    message: 'Пароль должен содержать как минимум 6 символов!'
                                                },
                                            ]}
                                        >
                                            <input
                                                className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                type="password"
                                                value={inputValues.password}
                                                onFocus={() => handleFocus('password')}
                                                readOnly
                                            />
                                        </Form.Item>
                                        <Form.Item label={<label style={{ color: "white" }}>Адрес</label>} rules={[
                                            { required: true, message: 'Пожалуйста, введите адрес!' },
                                        ]} name="address">
                                            <input
                                                className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                value={inputValues.address}
                                                onFocus={() => handleFocus('address')}
                                                readOnly
                                            />
                                        </Form.Item>




                                        <Form.Item>
                                            <Button className={'bg-green-600 text-white'} htmlType="submit">
                                                Отправить
                                            </Button>
                                            <div
                                                onClick={() => {
                                                    setIsRegister(false);
                                                }}
                                                className={'flex bg-red-500 p-2 rounded items-center cursor-pointer text-white flex-row mt-2 w-full self-end mb-2 mr-2 gap-2 text-lg'}>
                                                <BiArrowBack />
                                                <Typography className={'text-white'}>Логин</Typography>
                                            </div>
                                        </Form.Item>
                                    </Form> :
                                    <div className={'w-full h-auto flex flex-col items-center gap-4 mb-56'}>
                                        <form onSubmit={handleSubmit} className={'w-full'}>
                                            <div className={'text-balance text-black/50 font-bold mb-2'}>Телефон
                                            </div>
                                            {miniApp ? <button
                                                    onClick={handleTelegramLogin}
                                                    className='w-full bg-gray-800 text-white p-3 rounded-md hover:bg-gray-700'
                                                >
                                                    Request Contact from Telegram
                                                </button> :
                                                <input
                                                    className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                    type="text"
                                                    placeholder={`+998`}
                                                    value={inputValues.phoneNumber}
                                                    onChange={(e) => setInputValues({ ...inputValues, phoneNumber: e.target.value })}
                                                    onFocus={() => handleFocus('phoneNumber')}
                                                />}
                                            <div className={'text-balance text-black/50 font-bold mb-2 mt-4'}>Пароль
                                            </div>
                                            <input
                                                className={'w-full border rounded-lg p-2 focus:border-emerald-400 duration-300 border-black/20'}
                                                type={'password'}
                                                placeholder={`Пароль`}
                                                value={inputValues.password}
                                                onFocus={() => handleFocus('password')}
                                                readOnly
                                            />
                                            <div
                                                onClick={() => {
                                                    setIsRegister(true);
                                                }}
                                                className={'flex bg-blue-500 p-2 rounded items-center cursor-pointer text-white flex-row mt-2 w-full self-end mb-2 mr-2 justify-between text-[12px]'}>
                                                <FaRegUser />
                                                <Typography className={'text-white'}>Регистрация</Typography>
                                            </div>

                                            <button
                                                type="submit"
                                                className={'w-full h-16 flex items-center justify-center rounded-full bg-red-600 mt-6 text-2xl font-bold text-white outline-none cursor-pointer select-none'}
                                            >
                                                Войти
                                            </button>

                                        </form>

                                    </div>
                            }

                        </div>

                        <div className={'w-full flex flex-col items-start gap-3 absolute bottom-3'}>
                            <p className={' text-sm'}>
                                <span className={'text-black/50 font-bold mx-1'}>Продолжая авторизацию, вы соглашаетесь с</span>
                                <span className={'font-bold'}>политикой конфиденциальности</span>,
                                <span className={'font-bold'}>условиями сервиса</span> и
                                <span className={'font-bold'}>условиями продажи товаров</span>
                            </p>
                        </div>
                    </div>
                    :
                    <div className={'w-full h-auto flex flex-col items-center'}>
                        <div className={'w-72 h-auto flex flex-col gap-4 mb-56'}>
                            <div
                                className={'cursor-pointer w-full h-14 rounded-full bg-[#f2f2f2] flex items-center justify-center '}>
                                <div onClick={() => setMobileActive(true)}
                                     className={'text-lg font-semibold text-black/80'}>
                                    Войти по номеру телефона
                                </div>
                            </div>
                            <div
                                className={'cursor-pointer w-full h-20 rounded-lg bg-white flex items=center justify-center shadow-xl '}>
                                <img className={'w-[15%] h-full object-contain mr-2'} src={telegram_icon} />
                                <div className={'flex flex-col items-start'}>
                                    <div className={'text-lg font-semibold'}>OsosnExpress Telegram</div>
                                    <div className={'text-xs font-semibold text-black/80'}>Вы можете пользоваться
                                        услугами OsonExpress с помощью телеграм бота
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'w-full flex flex-col items-start gap-3'}>
                            <div className={'flex items-center text-sm'}>
                                <IoIosCheckmarkCircle className={'text-3xl text-red-500'} />
                                <div className={'text-black/50 font-bold mx-1'}>Получать</div>
                                <div className={'font-bold'}>предложения об акциях и скидках</div>
                            </div>
                            <div className={'flex items-center text-sm'}>
                                <IoIosCheckmarkCircle className={'text-3xl text-red-500'} />
                                <div className={'text-black/50 font-bold mx-1'}>Делиться</div>
                                <div className={'font-bold'}>данными с партнёрами OsonExpress</div>
                            </div>

                            <p className={' text-sm'}>
                                <span className={'text-black/50 font-bold mx-1'}>Продолжая авторизацию, вы соглашаетесь с</span>
                                <span className={'font-bold'}>политикой конфиденциальности</span>,
                                <span className={'font-bold'}>условиями сервиса</span> и
                                <span className={'font-bold'}>условиями продажи товаров</span>
                            </p>
                        </div>
                    </div>
            }
            {isKeyboardVisible && <CustomKeyboard isVisible={isKeyboardVisible} setIsVisible={setIsKeyboardVisible} onKeyPress={handleKeyPress} />}
        </div>
    );
}
