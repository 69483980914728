import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";
import Confetti from "react-confetti";
import './style.css';
import bg from './images/wheel_bg.jpg';

const data = [
    { option: "10% чегирма" },
    { option: "Бепул коффе" },
    { option: "Купон" },
    { option: "Совга юк" },
    { option: "2 млн ваучер" },
    { option: "Телефон" },
    { option: "Супер ютук" },
    { option: "янам" },
];

const WheelOfFortune = () => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [prizeHistory, setPrizeHistory] = useState([]);

    useEffect(() => {
        // Показываем модальное окно при загрузке компонента
        setShowModal(true);

        // Очистка истории при открытии страницы (тестовый режим)
        localStorage.removeItem('wheel_prizes');
        setPrizeHistory([]);
    }, []);

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * data.length);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
        setShowSnackbar(false); // Закрываем Snackbar перед новым спином
    };

    const handleStopSpinning = () => {
        setMustSpin(false);
        if (data[prizeNumber].option !== "Совга юк" && data[prizeNumber].option !== "янам") {
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 3000);
        }
        // Добавляем приз в историю
        const prize = data[prizeNumber].option;
        updatePrizeHistory(prize);

        setShowSnackbar(true); // Показать Snackbar после остановки
        setTimeout(() => setShowSnackbar(false), 3000); // Автоматически скрыть через 3 секунды
    };

    const updatePrizeHistory = (prize) => {
        const newHistory = [...prizeHistory, prize];
        setPrizeHistory(newHistory);
        localStorage.setItem('wheel_prizes', JSON.stringify(newHistory));
    };

    return (
        <div
            style={{
                background: `url(${bg}) center center`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'cover',
            }}
            className="relative w-full flex flex-col items-center justify-center h-screen"
        >
            {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <h1 className="text-4xl text-white font-bold mb-8 carnival-lights">OsonFortuna</h1>
            <div className="relative">
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    backgroundColors={["#3e3e3e", "#df3428"]}
                    textColors={["#ffffff"]}
                    onStopSpinning={handleStopSpinning}
                />
                {/* Гирлянда */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="flex justify-around w-full px-4">
                        {[...Array(8)].map((_, index) => (
                            <div
                                key={index}
                                className={`w-6 h-6 rounded-full carnival-bulb glow-${index % 4}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <button
                onClick={handleSpinClick}
                className="mt-8 px-6 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-md text-lg shadow-lg transition duration-300 carnival-button"
            >
                Doirani aylantiring!
            </button>
            <p className="mt-4 text-white text-xl carnival-text">Sovgangizni oling!</p>

            {/* Модальное окно */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg p-8 max-w-md mx-auto shadow-lg text-center">
                        <h2 className="text-2xl font-bold mb-4">Eslatma</h2>
                        <p className="text-lg mb-6">
                            Vaqtinchalik test Rejimida sovg`ani yutushni iloji yuq keyinchalik yangi rejimda ishlay boshlaydi
                        </p>
                        <button
                            onClick={() => setShowModal(false)}
                            className="px-6 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition duration-300"
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}

            {/* Кастомный Snackbar */}
            {showSnackbar && (
                <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 bg-black text-white px-4 py-2 rounded-md shadow-lg z-50">
                    Yutuq: {data[prizeNumber].option}
                </div>
            )}

            {/* История призов */}
            <div className="mt-8 p-4 bg-white bg-opacity-70 rounded-md shadow-lg">
                <h3 className="text-xl font-bold mb-2">Yutuqlar tarixi:</h3>
                {prizeHistory.length > 0 ? (
                    <ul className="list-disc list-inside">
                        {prizeHistory.map((prize, index) => (
                            <li key={index}>{prize}</li>
                        ))}
                    </ul>
                ) : (
                    <p>Hali yutuqlar yo'q.</p>
                )}
            </div>
        </div>
    );
};

export default WheelOfFortune;
