import React, {useEffect, useState} from "react";
import style from "./style.module.css";
import {setItemType} from "../store/actions/selectedItemTypeAction";
import {setDrawerOpen} from "../store/actions/drawerAction";
import {useDispatch, useSelector} from "react-redux";
import {FaChevronDown} from "react-icons/fa";
import SelectedProduct from "../SelectedProduct/SelectedProduct";
import {ON_SALE_PRODUCTS} from "../../CustomData/CustomData";
import ProductCard from "../ProductCard/ProductCard";
import {setSelectedProducts} from "../store/actions/selectedProductActions";
import {selectIsUserLoggedIn, selectUserData} from "../store/selectors/userSelectors";
import {useParams} from "react-router-dom";
import {selectDrawerOpen} from "../store/selectors/drawerSelector";
import {selectProducts} from "../store/selectors/productSelectors";
import {filterProducts} from "../../utils";
import {message} from "antd";
import {addToTotalPrice} from "../store/actions/priceAction";
import {useTelegram} from "../../hooks/TelegramHooh";

export default function MapContainer() {

    const dispatch = useDispatch()
    const [locationActive, setLocationActive] = useState(false)
    const isLogin = useSelector(selectIsUserLoggedIn);
    const userData = useSelector(selectUserData);
    const [backet, setBacket] = useState([]);
    const pom = useParams();
    const [isTgUser,setTgUser] = useState(false);
    const {tg,user,onClose} = useTelegram()
    const isDrawerOpen = useSelector(selectDrawerOpen);
    const [currentPrice, setCurrentPrice] = useState(0);
    const totalPrice = useSelector(state => state.price.totalPrice);
    const handleAddToTotalPrice = (price) => {
        dispatch(addToTotalPrice( parseInt(price))); // Добавляем 50 к общей цене
    };
    useEffect(() => {
    }, [totalPrice])
    const products = useSelector(selectProducts);

    useEffect(() => {
        if (user?.id?.length>0){
            setTgUser(true)
        } else {
            setTgUser(false)
        }
    }, [])
    useEffect(() => {
        setBacket(filterProducts(products?.products, JSON.parse(localStorage.getItem('basket'))))
        let t = 0;
        filterProducts(products?.products, JSON.parse(localStorage.getItem('basket'))).map(value => t += parseInt( value.price) * value.count)
       if (totalPrice===0){
           setCurrentPrice(t)
           handleAddToTotalPrice(t)
       } else {
           setCurrentPrice(t)
       }

    }, [products, totalPrice])
    return <>
        <div className={'w-full h-full rounded-3xl bg-[#ffffff] relative flex flex-col overflow-hidden '}>


            <div className={'w-full h-full flex flex-col items-start pl-3 pt-2 relative'}>

                <div className={'w-[90%] h-[12%] items-start flex flex-col'}>
                    <div
                        className={'w-full text-start text-2xl line-clamp-1 font-bold'}>{isTgUser?user.first_name : localStorage.getItem('login') ? localStorage.getItem('address') : 'адресс не установлен'}</div>
                    <div className={'w-full text-start text-2xl line-clamp-1 font-bold text-black/50'}>Savatchangiz
                    </div>
                </div>
                <FaChevronDown onClick={() => {
                    // dispatch(setItemType('Location'))
                    // dispatch(setDrawerOpen(true))
                }}
                               className={'select-none absolute top-4 right-3 text-xl cursor-pointer rounded-full duration-200  active:bg-[#f2f2f2] active:translate-y-0.5 '}/>

                <div className={style.container}>

                    {backet.map((value, index) => <SelectedProduct onClick={() => {
                        dispatch(setDrawerOpen(true))
                        dispatch(setSelectedProducts(value))
                        dispatch(setItemType("saleProduct"))
                    }}
                                                                   key={index}
                                                                   productQuantity={value.count} cover={value.cover}
                                                                   percentPromo={22} capacity={value.price}
                                                                   title={value.name}
                                                                   oldPrice={value.price} price={value.price}
                                                                   badgeText={'qweqw'} id={value.id}/>)}

                </div>

                <div className={style.bottomTip}>

                    <div className={'text-sm text-black/50 font-bold'}>Итого</div>
                    <div className={'text-3xl text-black/90 font-bold'}>{totalPrice === 0?currentPrice:totalPrice} so'm</div>

                    <div onClick={() => {
                        dispatch(setDrawerOpen(true))
                        dispatch(setItemType('Payment'))
                    }}
                         className={'cursor-pointer w-full h-16 flex items-center justify-center rounded-full bg-red-600 mt-2 text-center text-white'}>

                        <div className={'text-2xl font-bold'}>Davom etish</div>

                    </div>

                </div>

            </div>


        </div>
    </>
}
