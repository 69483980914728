import React from "react";
import { useDispatch } from "react-redux";
import { setItemType } from "../store/actions/selectedItemTypeAction";
import { setDrawerOpen } from "../store/actions/drawerAction";
import {setPromo} from "../store/actions/promoActions";

export default function PromoCard({obj, cardKey, cardType, title, cover }) {

    const dispatch = useDispatch()

    function linkHandler() {
        dispatch(setItemType('Promo'))
        dispatch(setDrawerOpen(true))
        dispatch(setPromo(obj))
    }

    return (
        <div onClick={linkHandler} key={cardKey} style={{ backgroundColor: `${cardType === "danger" ? `red` : `` || cardType === "popular" ? `yellow` : `` || cardType === "new" ? `green` : ``}` }} className={`cursor-pointer w-48 h-60 rounded-2xl flex flex-col items-start relative overflow-hidden`}>

            <img className={'w-40 h-fit object-contain absolute bottom-6 left-0 right-0 m-auto z-0 '}
                 src={'https://api.osonexpress.uz/' + cover} alt={title} />

            <div className={'z-10 w-full h-full flex flex-col items-start p-3 '}>
                <div className={'text-black text-xl font-semibold line-clamp-3'}>{title}</div>
            </div>

        </div>
    );
}
