import React, {useEffect, useState} from "react";

import image from "../assets/img/products/exampleProduct.png";
import {FiMinus, FiPlus} from "react-icons/fi";
import {CgMathPlus} from "react-icons/cg";
import {IoMdClose} from "react-icons/io";
import {message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {addToTotalPrice, subtractFromTotalPrice} from "../store/actions/priceAction";

export default function SelectedProduct({cover,percentPromo,title,oldPrice,price,capacity,badgeText,productQuantity,key,onClick,height,width,id}) {

    const [isActive,setActive] = useState(false)
    const [quantity,setQuantity] = useState(0)
    const [myCount, setMyCount] = useState(0); // Состояние для отслеживания количества товара в корзине

    const updateBasket = (productId, type) => {
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };

        // Проверяем, есть ли элемент с данным product_id
        const existingProductIndex = parsedData.products.findIndex(product => product.product_id === productId);

        if (existingProductIndex !== -1) {
            if (type === 'increment') {
                parsedData.products[existingProductIndex].count++;
            } else if (type === 'decrement') {
                if ( parsedData.products[existingProductIndex].count >0){
                    parsedData.products[existingProductIndex].count--;
                } else {
                    message.info('продукт не может быть ниже одного количество')
                }

            }
        } else {
            // Элемент не найден - добавляем новый элемент
            parsedData.products.push({ product_id: productId, count: 1 });
        }

        // Сохраняем обновленные данные в localStorage
        localStorage.setItem("basket", JSON.stringify(parsedData));
        setMyCount(getMycount(id, parsedData));
    };

    const getMycount = (productId, data) => {
        const existingProductIndex = data?.products?.findIndex(product => product?.product_id === productId);
        if (existingProductIndex !== -1) {
            return data?.products[existingProductIndex]?.count;
        } else {
            return 0;
        }
    };

    const handleIncrement = () => {
        handleAddToTotalPrice()
        updateBasket(id, 'increment');
    };

    const handleDecrement = () => {
        updateBasket(id, 'decrement');
        handleSubtractFromTotalPrice()
    };
    const totalPrice = useSelector(state => state.price.totalPrice);

    // Получаем функцию dispatch из Redux
    const dispatch = useDispatch();

    // Функции для обработки событий клика на кнопках
    const handleAddToTotalPrice = () => {
        dispatch(addToTotalPrice( parseInt(price))); // Добавляем 50 к общей цене
    };

    const handleSubtractFromTotalPrice = () => {
        dispatch(subtractFromTotalPrice(parseInt(price))); // Вычитаем 50 из общей цены
    };
        useEffect(()=>{},[])

    useEffect(()=>{
        const storedData = localStorage.getItem("basket");
        const parsedData = storedData ? JSON.parse(storedData) : { products: [] };
        const myCount = getMycount(id, parsedData);
        setMyCount(myCount);
        setQuantity(productQuantity)
    },[totalPrice,myCount,productQuantity])
    return<>
        <div className={'w-full h-20 flex relative'}>

            <IoMdClose onClick={()=>setQuantity(0)} className={'text-xl absolute top-3 right-3'}/>
            <div className={'w-[30%] rounded-xl relative overflow-hidden  h-full bg-[#f2f2f2]'}>
                <img className={'w-full h-full object-contain'} src={`https://api.osonexpress.uz/${cover[0]}`}/>
                {quantity? <div className={'absolute top-0 w-full h-full bg-black/30 left-0 flex flex-col items-center justify-center '}>
                    <div className={'text-3xl font-bold text-white'}>{myCount}</div>
                </div> : ``}

            </div>

            <div className={'w-[80%] flex flex-col justify-between items-start pl-2 '}>
                <div className={'w-auto flex flex-col items-start'}>
                    <div className={'text-balance font-bold'}>{title}</div>
                    <div className={'text-xs font-bold text-black/50'}>{capacity}</div>
                </div>

                <div className={'w-full flex justify-between items-center pr-1 select-none'}>

                    <div className={'w-20 h-8 rounded-full bg-[#f2f2f2] flex items-center justify-between px-2 '}>

                        <FiMinus onClick={()=>handleDecrement()} />

                        <div className={'font-bold'}>{myCount}</div>

                        <FiPlus onClick={()=>handleIncrement()} />

                    </div>

                    <div className={'w-28 h-8 flex items-center justify-center gap-2 px-1'}>
                        {oldPrice? <span className={'line-through text-black/50 font-bold text-[10px] line-clamp-1 '}>{oldPrice * myCount } S </span>:`` }
                        <span className={'text-[12px] font-bold flex items-center gap-1 line-clamp-1'}>{price *myCount} S </span>
                    </div>

                </div>

            </div>

        </div>
    </>
}
