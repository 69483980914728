import React from "react";
import Slider from "react-slick";
import prevIcon from "../assets/img/icons/prevArrow.svg";
import nextIcon from "../assets/img/icons/nextArrow.svg";
import ProductCard from "../ProductCard/ProductCard";
import { setDrawerOpen } from "../store/actions/drawerAction";
import { setSelectedProducts } from "../store/actions/selectedProductActions";
import { setItemType } from "../store/actions/selectedItemTypeAction";
import { useDispatch } from "react-redux";
import { extractData } from "../../utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ onClick }) => (
    <div
        onClick={onClick}
        style={{
            display: "block",
            zIndex: 10,
            position: 'absolute',
            right: 0,
            top: '35%',
            transform: 'translateY(-50%)',
            pointerEvents: 'auto' // Обеспечивает, что стрелка взаимодействует только с событиями клика.
        }}
    >
        <img src={nextIcon} alt="Next" style={{ width: "80px", height: "80px" }} />
    </div>
);

const PrevArrow = ({ onClick }) => (
    <div
        onClick={onClick}
        style={{
            display: "block",
            zIndex: 10,
            position: 'absolute',
            left: -25,
            top: '5%',
            transform: 'translateY(50%)',
            pointerEvents: 'auto' // Обеспечивает, что стрелка взаимодействует только с событиями клика.
        }}
    >
        <img src={prevIcon} alt="Prev" style={{ width: "80px", height: "80px" }} />
    </div>
);

export default function Custom_Swiper({ array }) {
    const dispatch = useDispatch();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1918,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const getProductCount = (productId) => {
        const data = localStorage.getItem("basket");
        const parsedData = data ? JSON.parse(data) : { products: [] };
        const existingProductIndex = parsedData.products.findIndex(
            (product) => product.product_id === productId
        );
        if (existingProductIndex !== -1) {
            return {
                isActive: parsedData.products[existingProductIndex].count > 0,
                count: parsedData.products[existingProductIndex].count,
            };
        } else {
            return { isActive: false, count: 0 };
        }
    };

    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            {/* Изолирующий контейнер */}
            <div style={{ maxWidth: '1200px', margin: '0 auto', overflow: 'hidden', position: 'relative' }}>
                <Slider  {...settings} style={{ width: '100%' }}>
                    {array.map((product, index) => (
                        <div
                            key={index}
                            className="flex-shrink-0 flex items-center justify-center"
                            style={{
                                padding: "0 0.5rem",
                                height: 'inherit',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ProductCard
                                onClick={() => {
                                    dispatch(setDrawerOpen(true));
                                    dispatch(setSelectedProducts(product));
                                    dispatch(setItemType("saleProduct"));
                                }}
                                cover={product.cover}
                                percentPromo={extractData(product.description).discount}
                                capacity={product.brief_description}
                                title={product.name}
                                oldPrice={extractData(product.description)?.minus || 0}
                                price={product.price}
                                id={product.id}
                                badgeText={product.brands}
                                initialState={getProductCount(product.id)}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}
