export const ADD_TO_TOTAL_PRICE = 'ADD_TO_TOTAL_PRICE';
export const SUBTRACT_FROM_TOTAL_PRICE = 'SUBTRACT_FROM_TOTAL_PRICE';

export const addToTotalPrice = (amount) => {
    return {
        type: ADD_TO_TOTAL_PRICE,
        payload: amount
    };
};

export const subtractFromTotalPrice = (amount) => {
    return {
        type: SUBTRACT_FROM_TOTAL_PRICE,
        payload: amount
    };
};
