import {configureStore} from "@reduxjs/toolkit";
import {drawerReducer} from "./reducers/drawerReducer";
import {selectedProductReducer} from "./reducers/selectedProductReducer";
import {selectedItemTypeReducer} from "./reducers/selectedItemTypeReducer";
import userReducer from "./reducers/userReducer";
import historyReducer from "./reducers/historyReducer";
import categoryReducer from "./reducers/categoryReducer";
import promoReducer from "./reducers/promoReducer";
import ProductReducer from "./reducers/ProductReducer";
import priceReducer from "./reducers/priceReducer";

export const store = configureStore({
    reducer : {
        drawerState : drawerReducer,
        selectedProduct : selectedProductReducer,
        selectedItemType : selectedItemTypeReducer,
        user : userReducer,
        history : historyReducer,
        categories : categoryReducer,
        promo : promoReducer,
        products : ProductReducer,
        price : priceReducer,
    }
})
