import React, {useEffect, useState} from 'react';
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";


import 'swiper/css';

import 'swiper/css/navigation';

import nextIcon from '../../components/assets/img/icons/nextArrow.svg';
import prevIcon from '../../components/assets/img/icons/prevArrow.svg';
import Multiple_Product_Card from "../../components/Multiple_Product_Card/Multiple_Product_Card";
import {setItemType} from "../../components/store/actions/selectedItemTypeAction";
import {setDrawerOpen} from "../../components/store/actions/drawerAction";
import {useDispatch} from "react-redux";
import {fetchCategoriesById, fetchCategoriesType} from "../../Api/Categories/Categories";
import {addToCategory} from "../../components/store/actions/categoryAction";
import {findSliderCategory} from "../../utils";
import {addToHistory} from "../../components/store/actions/historyAction";

export default function Mob_Multiple_Product_Card_Swiper() {

    const dispatch = useDispatch()


    const  [slider,setSlider] = useState();

    useEffect(()=>{
        fetchSlider()
    },[])

    const fetchSlider = async ()=>{
        const data = await fetchCategoriesType('uz');
        dispatch(addToCategory(data))
        const myId =  findSliderCategory(data)
        const slide =await  fetchCategoriesById('uz',myId[0].categories[0].id)
        setSlider(slide.data.products)
        console.log(slide.data.products)



    }

    return<>
        <div className={'w-full h-72 flex items-center gap-3 relative'}>

            {/*There need Swiper*/}

            <button className={`swiper-button image-swiper-button-prev__ max-[1000px]:hidden absolute z-10 top-[40%] left-0`}>

                {/*<IoIosArrowBack/>*/}
                <img src={prevIcon} className={'w-fit h-20 object-contain'}/>

            </button>
            <button className={`swiper-button image-swiper-button-next__ max-[1000px]:hidden absolute z-10 top-[40%] right-0 text-3xl text-white`}>

                {/*<IoIosArrowForward/>*/}
                <img src={nextIcon} className={'w-fit h-20 object-contain'}/>

            </button>
            <Swiper
                navigation={{
                    nextEl: ".image-swiper-button-next__",
                    prevEl: ".image-swiper-button-prev__",
                    disabledClass: "swiper-button-disabled"
                }}
                modules={[Navigation , Autoplay]}
                loop={true}
                className={'w-full h-full'}
                spaceBetween={5}
                breakpoints={{
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    // когда ширина экрана меньше 768px
                    600: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    // когда ширина экрана меньше 1024px
                    800: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    }
                }}
            >

                {slider?.map((value, index) => <SwiperSlide key={index} className={'w-56 h-full'}>
                    <Multiple_Product_Card onClick={()=>{
                        dispatch(setItemType('Stories'))
                        dispatch(setDrawerOpen(true))
                        dispatch(addToHistory(value))
                    }} cover={value.cover} title={value.name}/>
                </SwiperSlide>)}

            </Swiper>

        </div>
    </>
}
